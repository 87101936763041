import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Modal, Spinner, } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { CAMPAIGN_DELETE, CAMPAIGN_DETAIL_FULL, CAMPAIGN_LIST, fetchData } from './Service';
import { Context } from './Context';
import { PageLoader } from './Helper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const CampaignData = (props) => {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)
    const [Modal1, setModal1] = useState(false);
    const [tinyLoader, setTinyLoader] = useState(false)

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const [dataList, setDataList] = useState([])
    const [data, setData] = useState()
    const [schedule, setSchedule] = useState()

    const getUsersList = (e) => {
        fetchData(CAMPAIGN_LIST + (props.viewType === "user" ? "?userId=" + context.id : ""), 'GET', "", true, false, (res) => {
            if (res.status === "success") {
                setDataList(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }

    const viewCampaign = (id) => {
        PageLoader(true);
        fetchData(CAMPAIGN_DETAIL_FULL, 'POST', {campaignId: id}, true, false, (res) => {
            if (res.status === "success") {
                setData(res.records)
                res.records && res.records.CampaignRuntime && res.records.CampaignRuntime.schedule && setSchedule(JSON.parse(res.records.CampaignRuntime.schedule))
                handleShow1()
                PageLoader(false);
            } else {
                // displayError(res.errors)
            }
        });
    }

    const editCampaign = (id) => {
        setContext(prevValue => ({
            ...prevValue,
            campaign: { ...prevValue.campaign, id: id, path: "campaign" }
        }));

        navigate("/campaign")
    }

    const deleteCampaign = (id) => {
        setTinyLoader("delete");

        fetchData(CAMPAIGN_DELETE + "/" + id, 'DELETE', "", true, false, (res) => {
            setTinyLoader(false);
            if (res.status === "success") {
                getUsersList()
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        if(context && context.id){
            getUsersList()
        }
    }, [])

    return (
        <>
            <Table responsive bordered hover>
                <thead>
                    <tr>
                        <th width="100px">#</th>
                        <th>Campaign Name</th>
                        {props.viewType === "admin" && <th>User's Name</th> }
                        <th>Date</th>
                        <th width="200px">Status</th>
                        <th width="120px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {dataList && dataList.length ? dataList.map((campaign, i) => (
                            <tr key={campaign.id}>
                                <td>{campaign.id}</td>
                                <td>Campaign {campaign.id}</td>
                                {props.viewType === "admin" && <td>{campaign.User && campaign.User.firstName} {campaign.User && campaign.User.lastName}</td> }
                                <td>{ moment(campaign.createdAt).format("Do MMM YY, h:mm a") }</td>
                                <td>{campaign.status}</td>
                                <td>
                                <Button variant="view" onClick={() => viewCampaign(campaign.id)}><FontAwesomeIcon icon="fa-solid fa-eye" /></Button>
                                <Button variant="edit" onClick={() => editCampaign(campaign.id)}><FontAwesomeIcon icon="fa-solid fa-pencil" /></Button>
                                {props.viewType === "admin" && <Button variant="delete" onClick={() => deleteCampaign(campaign.id)}>{ tinyLoader === "delete" ? <Spinner animation="grow" size="sm" /> : <FontAwesomeIcon icon="fa-solid fa-trash-can" /> }</Button>}
                            </td>
                        </tr>
                    )) : <tr><td colspan="5" className='text-center text-danger'>No Campaign Found</td></tr>}
                </tbody>
            </Table>
                        
            <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block saved-campaign-model">
                <Modal.Header closeButton>
                    <div className="model-head-logo"><img src={'/images/logo.png'} alt="Connect X"/></div>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-heding">Campaign <span className="mod-status">{data && data.StartCampaign && data.StartCampaign.status === "in-progress" ? "In Progress" : "Completed"}</span></div>
                    <div className="campaign-section">
                        { data && data.CampaignDisplay && <div className="section-list-block">
                            <h3>1. Campaign </h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Selection</div> 
                                <div className="campaign-input-blk"><p>{data.CampaignDisplay.display}</p></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignDisplay.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignBudget && <div className="section-list-block">
                            <h3>2. Campaign budget</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Campaign Budget</div> 
                                <div className="campaign-input-blk">{data.CampaignBudget.currency === "USD" ? "USD ($)" : "EUR (€)"} {data.CampaignBudget.budget}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignBudget.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignFunding && <div className="section-list-block">
                            <h3>3. Funding</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Campaign Budget</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignFunding.fundingType && data.CampaignFunding.fundingType.split(",").map((i, v) => (
                                        <p>{i}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignFunding.comments}</div>
                            </div>
                        </div>}
                        { data && data.CampaignBanner && <div className="section-list-block">
                            <h3>4. Banners & Landingpage</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Image Banner</div> 
                                <div className="campaign-input-blk"><a href={data.CampaignBanner.imageBannerType === "image" ? process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.imageBanner : data.CampaignBanner.imageBanner } target="_blank" rel="noreferrer"><img src={data.CampaignBanner.imageBannerType === "image" ? process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.imageBanner : data.CampaignBanner.imageBanner } alt="ConnectX Activation" title="Image Banner"/></a></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Video Banner</div> 
                                <div className="campaign-input-blk"><a href={data.CampaignBanner.videoBannerType === "video" ? process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.videoBanner : data.CampaignBanner.videoBanner } target="_blank" rel="noreferrer">{data.CampaignBanner.videoBannerType === "video" ? process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.videoBanner : data.CampaignBanner.videoBanner }</a></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Native Banner</div> 
                                <div className="campaign-input-blk"><a href={process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.nativeBannerIcon } target="_blank" rel="noreferrer"><img src={process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.nativeBannerIcon } alt="ConnectX Activation"  title="Native Banner Icon"/></a> <a href={process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.nativeBannerImage } target="_blank" rel="noreferrer"><img src={process.env.REACT_APP_AWS_S3_URL + data.CampaignBanner.nativeBannerImage } alt="ConnectX Activation"  title="Native Banner main Image"/></a></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Title</div> 
                                <div className="campaign-input-blk">{data.CampaignBanner.title}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Description</div> 
                                <div className="campaign-input-blk">{data.CampaignBanner.description}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Landing Page/URL</div> 
                                <div className="campaign-input-blk"><a href={data.CampaignBanner.landingPageUrl} target="_blank" rel="noreferrer">{data.CampaignBanner.landingPageUrl}</a></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignBanner.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignRuntime && <div className="section-list-block">
                            <h3>5. Runtime</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Start Date</div> 
                                <div className="campaign-input-blk">{moment(data.CampaignRuntime.startDate).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">End Date</div> 
                                <div className="campaign-input-blk">{moment(data.CampaignRuntime.endDate).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Schedule</div> 
                                <div className="campaign-input-blk">
                                    <p><span>Monday:</span> {schedule && schedule.mon_l} to {schedule && schedule.mon_u}</p>
                                    <p><span>Tuesday:</span> {schedule && schedule.tue_l} to {schedule && schedule.tue_u}</p>
                                    <p><span>Wednesday:</span> {schedule && schedule.wed_l} to {schedule && schedule.wed_u}</p>
                                    <p><span>Thursday:</span> {schedule && schedule.thu_l} to {schedule && schedule.thu_u}</p>
                                    <p><span>Friday:</span> {schedule && schedule.fri_l} to {schedule && schedule.fri_u}</p>
                                    <p><span>Saturday:</span> {schedule && schedule.sat_l} to {schedule && schedule.sat_u}</p>
                                    <p><span>Sunday:</span> {schedule && schedule.sun_l} to {schedule && schedule.sun_u}</p>
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignRuntime.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignCountry && <div className="section-list-block">
                            <h3>6. Countries</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Countries where campaign must run</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignCountry.conuntry && JSON.parse(data.CampaignCountry.conuntry).map((i, v) => (
                                        <p>{i.label}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignCountry.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignAccuracy && <div className="section-list-block">
                            <h3>7. Accuracy and/or Precision of location targeting</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Selection</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignAccuracy.targetType && data.CampaignAccuracy.targetType.split(",").map((i, v) => (
                                        <p>{i}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Upload location data</div> 
                                <div className="campaign-input-blk">{ data.CampaignAccuracy.fileUrl && <a href={process.env.REACT_APP_AWS_S3_URL + data.CampaignAccuracy.fileUrl} target="_blank" rel="noreferrer">{process.env.REACT_APP_AWS_S3_URL + data.CampaignAccuracy.fileUrl}</a>}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Secondary volume line</div> 
                                <div className="campaign-input-blk">{ data.CampaignAccuracy.secondryVolumeLine ? "YES" : "No" }</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignAccuracy.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignLanguageTargeting && <div className="section-list-block">
                            <h3>8. Language Targeting</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Countries where campaign must run</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignLanguageTargeting.language && JSON.parse(data.CampaignLanguageTargeting.language).map((i, v) => (
                                        <p>{i.label}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignLanguageTargeting.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignIPTargeting && <div className="section-list-block">
                            <h3>9. IP Targeting</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Upload IP Ranges</div> 
                                <div className="campaign-input-blk">{ data.CampaignIPTargeting.fileUrl && <a href={process.env.REACT_APP_AWS_S3_URL + data.CampaignIPTargeting.fileUrl} target="_blank" rel="noreferrer">{process.env.REACT_APP_AWS_S3_URL + data.CampaignIPTargeting.fileUrl}</a>}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignIPTargeting.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignRichMedia && <div className="section-list-block">
                            <h3>10. Rich Media</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Selection</div> 
                                <div className="campaign-input-blk">{data.CampaignRichMedia.mediaType}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Phone number where intelligence can be forwarded to</div> 
                                <div className="campaign-input-blk">{data.CampaignRichMedia.mediaPhone}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignRichMedia.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignDataDerived && <div className="section-list-block">
                            <h3>11. Upload device ids and usage of data derived from past campaigns</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Selection</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignDataDerived.derivedType && data.CampaignDataDerived.derivedType.split(",").map((i, v) => (
                                        <p>{i}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Upload lists of device id's</div> 
                                <div className="campaign-input-blk">{ data.CampaignDataDerived.fileUrl && <a href={process.env.REACT_APP_AWS_S3_URL + data.CampaignDataDerived.fileUrl} target="_blank" rel="noreferrer">{process.env.REACT_APP_AWS_S3_URL + data.CampaignDataDerived.fileUrl}</a>}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignDataDerived.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignSensitivity && <div className="section-list-block">
                            <h3>12. Select Sensitivity</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Selection</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignSensitivity.sensitivityType && data.CampaignSensitivity.sensitivityType.split(",").map((i, v) => (
                                        <p>{i}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignSensitivity.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignAttribution && <div className="section-list-block">
                            <h3>13. Level of Attribution</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">DSP/Vendor can obtain knowledge of</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignAttribution.attributionType && data.CampaignAttribution.attributionType.split(",").map((i, v) => (
                                        <p>{i}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignAttribution.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignReport && <div className="section-list-block">
                            <h3>14. Reporting</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Selection</div> 
                                <div className="campaign-input-blk">
                                    {data.CampaignReport.reportType && data.CampaignReport.reportType.split(",").map((i, v) => (
                                        <p>{i}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignReport.comments}</div>
                            </div>
                        </div> }
                        { data && data.CampaignSubmission &&<div className="section-list-block">
                            <h3>15. Submit Campaign</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">How do you like us to contact you?</div> 
                                <div className="campaign-input-blk">{data.CampaignSubmission.contactUsType} - {data.CampaignSubmission.contactUs}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.CampaignSubmission.comments}</div>
                            </div>
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CampaignData;