import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_ACCURACY, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, DownloadButton, PageLoader, displayError, uploadFileToAWSBucket } from '../../components/Helper';
import _ from 'lodash';

const Accuracy = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [locationData, setLocationData] = useState(null);
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignaccuracies"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
                setLocationData(res.records.fileUrl)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/countries')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-accuracy'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            targetType: _.toString(formData.getAll("targetType[]")),
            fileUrl: locationData,
            secondryVolumeLine:  formData.get("secondryVolumeLine"),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_ACCURACY, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/language-targeting');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    const uploadFile = async (e) => {
        setFileUploadLoader(true)
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setLocationData(file)
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>7. Accuracy and/or Precision of location targeting</h1>
                        <p>Select level of precision and upload your location data.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-accuracy">
                                        <div className="form-group">
                                            <label className="rc-block">GEO-Fencing, GPS precision <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="targetType[]" value="GEO-Fencing, GPS precision" defaultChecked={data && data.targetType.includes("GEO-Fencing, GPS precision")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">GEO-Fencing, IP Precision <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="targetType[]" value="GEO-Fencing, IP Precision" defaultChecked={data && data.targetType.includes("GEO-Fencing, IP Precision")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Zipcode <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="targetType[]" value="Zipcode" defaultChecked={data && data.targetType.includes("Zipcode")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">City <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="targetType[]" value="City" defaultChecked={data && data.targetType.includes("City")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Other <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="targetType[]" value="Other" defaultChecked={data && data.targetType.includes("Other")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <span id="form-error-targetType" className='form-input-error' ></span>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Upload location data <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span></label>
                                            <div className="double-field-block">
                                                <div className="file-upload-block">
                                                    <Form.Control type="file" onChange={(e) => uploadFile(e)}/>
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload location data</Button>
                                                </div>
                                                { locationData && <DownloadButton url={locationData} /> }
                                            </div>
                                            <span id="form-error-fileUrl" className='form-input-error' ></span>
                                        </div>
                                        <div className="form-group">
                                            <label className="rc-block">Secondary volume line <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="secondryVolumeLine" value="Secondary volume line" defaultChecked={data && data.secondryVolumeLine && data.secondryVolumeLine.includes("Secondary volume line")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>Location Targeting is a powerful technique that enables you to deploy information in (very) specific locations. Location Targeting can be restrictive and have an influence on the desired budget you would like to be spend.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Location Targeting Explained</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Location Targeting Explained</div>
                <h5>Location Targeting with CONNECTX Activation Platform</h5>
                <p>Location targeting is a key feature of the CONNECTX Activation Platform, allowing you to precisely target your desired audience based on their geographical location. Understanding the different levels of precision is crucial to maximizing your campaign's effectiveness. Here's a breakdown of how our platform's precision levels work:</p>
                <h5>1. GPS Precision:</h5>
                <ul>
                    <li><b>Accuracy:</b> As precise as 50 yards.</li>
                    <li><b>How to use:</b> You need to provide us with a list of latitude/longitude coordinates for your targeted locations.</li>
                    <li><b>Setting GEO-Fences:</b> Define the radius for your GEO-Fences in yards. The platform will apply this radius to each location in your document.</li>
                    <li><b>Note:</b> Smaller GEO-Fences mean fewer impressions served. This can affect how much of your budget is utilized. </li>
                </ul>
                <h5>2. IP Precision:</h5>
                <ul>
                    <li><b>Accuracy:</b> No more accurate than 2km (approximately 2000 yards).</li>
                    <li><b>How to use:</b> Choose IP Targeting if you're okay with this level of precision.</li>
                    <li><b>Upload options:</b> Along with latitude/longitude coordinates, you can also upload lists of zip codes or cities. </li>
                    <li><b>City Targeting:</b> Want to target an entire city with a 15,000-yard GEO-Fence? It's possible with our platform!</li>
                </ul>
                <h5>Things to Consider:</h5>
                <ul>
                    <li><b>Budget Utilization:</b> The more precise your targeting, the fewer times your banners or information will be displayed. This means that highly targeted campaigns may not use up the entire budget allocated.</li>
                    <li><b>Secondary Volume:</b> If you're concerned about achieving the desired impressions, consider using our secondary volume feature. With this, we prioritize achieving as many impressions as possible within the targeting parameters you've set. If there's any leftover budget, we'll deploy it in a manner closest to your specified targeting parameters.</li>
                </ul>
                <p>In conclusion, location targeting is a balance of precision and reach. While narrowing down your target can yield highly relevant impressions, it might limit the total number of impressions. With the CONNECTX Activation Platform, you have the flexibility to choose the level of precision that best suits your campaign's objectives.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Accuracy;