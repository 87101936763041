import { Container, Row, Col, Table, Spinner } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Aside from '../../components/Aside';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../components/Context';
import { DASHBOARD_STATISTICS, fetchData } from '../../components/Service';
import { displayError } from '../../components/Helper';
import moment from 'moment/moment';

const Dashboard = () => {
    const [context] = useContext(Context)
    const [statisticsData, setStatisticsData] = useState("")
    const [countLoader, setCountLoader] = useState(false)

    useEffect(() => {
        setCountLoader(true);

        fetchData(DASHBOARD_STATISTICS, 'GET', "", true, false, (res) => {
            setCountLoader(false);
            if (res.status === "success") {
                setStatisticsData(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }, [])

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <Row>
                    <Col lg={3} md={4}>
                        <Aside></Aside>
                    </Col>
                    <Col lg={9} md={8}>
                        <div className="dashboard-content-block">
                            <div className="dashbaord-heading">
                                Dashboard
                            </div>
                            <div className="admin-table-block">
                                <Table responsive bordered hover>
                                    <tbody>
                                        <tr>
                                            <th>Name:</th>
                                            <td>{context && context.firstName} {context && context.lastName}</td>
                                        </tr>
                                        <tr>
                                            <th>Email Id:</th>
                                            <td>{ context && context.email }</td>
                                        </tr>
                                        <tr>
                                            <th>Phone Number:</th>
                                            <td>{ context && context.phone }</td>
                                        </tr>
                                        <tr>
                                            <th>Last Login:</th>
                                            <td>{context && context.lastLogin ? moment(context.lastLogin).format("Do MMM YY, h:mm a") : "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className="dashboard-card-block">
                                <Row>
                                    <Col md={4}>
                                        <div className="dashboard-card">
                                            <h2>{countLoader ? <Spinner animation="border" /> : (statisticsData && statisticsData.userCount)}</h2>
                                            <h4>Users</h4>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="dashboard-card">
                                            <h2>{countLoader ? <Spinner animation="border" /> : (statisticsData && statisticsData.campaignInProgress)}</h2>
                                            <h4>Campaigns In Progress</h4>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="dashboard-card">
                                            <h2>{countLoader ? <Spinner animation="border" /> : (statisticsData && statisticsData.campaignSubmitted)}</h2>
                                            <h4>Campaigns Submitted</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>
        </>
    );
};

export default Dashboard;