import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Context } from "./Context";
import Login from "../modules/login/login";
import GetStarted from "../modules/get-started/get-started";
import Campaign from "../modules/campaign/campaign";
import CampaignBudget from "../modules/campaign-budget/campaign-budget";
import Funding from "../modules/funding/funding";
import BannerLandingpage from "../modules/banner-landingpage/banner-landingpage";
import Runtime from "../modules/runtime/runtime";
import Countries from "../modules/countries/countries";
import Accuracy from "../modules/accuracy/accuracy";
import RichMedia from "../modules/rich-media/rich-media";
import DataDerived from "../modules/data-derived/data-derived";
import Sensitivity from "../modules/sensitivity/sensitivity";
import Attribution from "../modules/attribution/attribution";
import Reporting from "../modules/reporting/reporting";
import SubmitCampaign from "../modules/submit-campaign/submit-campaign";
import ThankYou from "../modules/thank-you/thank-you";
import Dashboard from "../modules/admin-dashboard/dashboard";
import Users from "../modules/admin-dashboard/users";
import Setting from "../modules/admin-dashboard/setting";
import ChangePassword from "../modules/admin-dashboard/change-password";
import { AUTH_USER_DETAIL, fetchData } from "./Service";
import CampaignList from "../modules/admin-dashboard/campaign-list";
import SavedCampaigns from "../modules/saved-campaigns/saved-campaigns";
import Languages from "../modules/languages/languages";
import IPTargeting from "../modules/ip-targeting/ip-targeting";
import Loading from "../modules/pages/Loading";

const Routing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [context, setContext] = useState()
    const [loader, setLoader] = useState(false)
    const[token] = useState(localStorage.getItem('__cxtoken'))

    useEffect(() => {
        if(token && !context){
            setLoader(false);
            fetchData(AUTH_USER_DETAIL, 'POST', {}, true, false, (res) => {
                setLoader(false);
                if (res.status === "success") {
                    setContext(res.records)
    
                    if (res.records.role === 'user') {
                        //if(!["/saved-campaigns", "/get-started"].includes(location.pathname)) {
                          //  navigate(`/${res.records.campaign.path}`);
                        //}
                    } else {
                        if(["/login"].includes(location.pathname)) {
                            navigate(`/admin/dashboard`);
                        }
                    }
                } else {
                    navigate('/');
                }
            });
        }
    }, [])

    return (
        <Context.Provider value={[ context, setContext ]}>
            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<Login />} />
                { context && context.id ?
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route path="/get-started" element={<GetStarted />} />
                        <Route path="/campaign" element={<Campaign />} />
                        <Route path="/campaign-budget" element={<CampaignBudget />} />
                        <Route path="/funding" element={<Funding />} />
                        <Route path="/banner-landingpage" element={<BannerLandingpage />} />
                        <Route path="/runtime" element={<Runtime />} />
                        <Route path="/countries" element={<Countries />} />
                        <Route path="/accuracy" element={<Accuracy />} />
                        <Route path="/language-targeting" element={<Languages />} />
                        <Route path="/ip-targeting" element={<IPTargeting />} />
                        <Route path="/rich-media" element={<RichMedia />} />
                        <Route path="/data-derived" element={<DataDerived />} />
                        <Route path="/sensitivity" element={<Sensitivity />} />
                        <Route path="/attribution" element={<Attribution />} />
                        <Route path="/reporting" element={<Reporting />} />
                        <Route path="/submit-campaign" element={<SubmitCampaign />} />
                        <Route path="/thank-you" element={<ThankYou />} />
                        <Route path="/saved-campaigns" element={<SavedCampaigns />} />
                        { context.role === "admin" && <Route path="/admin">
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="users" element={<Users />} />
                            <Route path="submitted-campaign" element={<CampaignList />} />
                            <Route path="setting" element={<Setting />} />
                            <Route path="change-password" element={<ChangePassword />} />
                        </Route> }
                    </> 
                :
                    <Route path="/*" element={<Loading />} />
                }
            </Routes>
        </Context.Provider>
    );
};

export default Routing;
