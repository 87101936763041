import logo from "../../logo.svg";

const Loading = (props) => {
    return (
        <div id="full-page-loader" className="loader-block">
            <div className="loader"></div>
        </div>
    );
};

export default Loading;
