import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_SENSIVITY, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import _ from 'lodash';

const Sensitivity = () => {

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignsensitivities"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        //setTinyloader(true)
        //setActionType(type)
        navigate('/data-derived')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-senstivity'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            sensitivityType: _.toString(formData.getAll("sensitivityType[]")),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_SENSIVITY, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/attribution');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>12. Select Sensitivity</h1>
                        <p>Select the category of your campaign. Multiple selections is possible.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-senstivity">
                                        <Row>
                                            <Col lg={6}>
                                                <div className="form-group">
                                                    <label className="rc-block">Financing of Terrorism
                                                        <input type="checkbox" name="sensitivityType[]" value="Financing of Terrorism" defaultChecked={data && data.sensitivityType.includes("Financing of Terrorism")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">Wanted for Terrorism
                                                        <input type="checkbox" name="sensitivityType[]" value="Wanted for Terrorism" defaultChecked={data && data.sensitivityType.includes("Wanted for Terrorism")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">To be served in disputed regions
                                                        <input type="checkbox" name="sensitivityType[]" value="To be served in disputed regions" defaultChecked={data && data.sensitivityType.includes("To be served in disputed regions")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">To be served in areas of active conflict
                                                        <input type="checkbox" name="sensitivityType[]" value="To be served in areas of active conflict" defaultChecked={data && data.sensitivityType.includes("To be served in areas of active conflict")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">Illegal trade
                                                        <input type="checkbox" name="sensitivityType[]" value="Illegal trade" defaultChecked={data && data.sensitivityType.includes("Illegal trade")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <span id="form-error-sensitivityType" className='form-input-error' ></span>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="form-group">
                                                    <label className="rc-block">Information on Hacking
                                                        <input type="checkbox" name="sensitivityType[]" value="Information on Hacking" defaultChecked={data && data.sensitivityType.includes("Information on Hacking")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">Wanted for Hacking
                                                        <input type="checkbox" name="sensitivityType[]" value="Wanted for Hacking" defaultChecked={data && data.sensitivityType.includes("Wanted for Hacking")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">Missing person
                                                        <input type="checkbox" name="sensitivityType[]" value="Missing person" defaultChecked={data && data.sensitivityType.includes("Missing person")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">Wanted by law enforcement
                                                        <input type="checkbox" name="sensitivityType[]" value="Wanted by law enforcement" defaultChecked={data && data.sensitivityType.includes("Wanted by law enforcement")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                    <label className="rc-block">Other
                                                        <input type="checkbox" name="sensitivityType[]" value="Other" defaultChecked={data && data.sensitivityType.includes("Other")}/>
                                                        <span className="checkmark checkbox-cm"></span>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>By categorising your campaign accordingly, only accepting distributing partners will be presented the campaign.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Sensitivity</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow2}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Tor, Signal & Safety</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Sensitivity</div>
                <h5>Launch Your Campaign with Precision and Peace of Mind</h5>
                <p>At CONNECTX Activation, we understand the importance of context and relevance in today's dynamic digital landscape. When you entrust your campaign with us, you're not just getting an ad placement – you're getting a thoughtfully curated distribution that aligns with your messaging and values.</p>
                <h5>Smart Categorization for Maximum Impact:</h5>
                <p>Every campaign is unique, and we respect its individuality. By accurately categorizing your campaign, we ensure it gets launched using the most suitable networks and inventory available. This approach not only elevates campaign performance but also ensures it resonates with the right audience, in the right places.</p>
                <h5>Respect for Publishers and Their Preferences:</h5>
                <p>Just as every brand and campaign has its identity, so do our network publishers. While some might steer clear of terrorism-related campaigns, others may not be in favor of anti-hacking content. Recognizing these distinctions, we make sure your campaigns are placed where they are welcome, mitigating any potential conflicts and enhancing brand integrity.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Tor, Signal & Safety</div>
                <h5>The Importance of Using TOR and Signal in Sensitive Scenarios</h5>
                <p>When dealing with sensitive campaigns, such as seeking information on terrorism or reporting malicious hacking activities, it's of paramount importance that both the provider and the receiver of the information take steps to ensure their digital safety. In circumstances where sharing information might put someone in potential danger, the choice of communication tool can make all the difference. This is where the TOR browser for emails and the Signal messenger app come into play. Here’s why:</p>
                <h5>1. Anonymity and Privacy with TOR:</h5>
                <ul>
                    <li><b>Protection of IP Address:</b> The TOR browser obscures a user's IP address, making it extremely difficult to determine the origin of the connection. This ensures that whistleblowers, informants, or any person providing sensitive information cannot be easily traced back to a specific location or identity.</li>
                    <li><b>Layered Encryption:</b> The name TOR originates from "The Onion Router", which denotes its multi-layered encryption approach. Each piece of data sent through the network is encrypted multiple times, like layers of an onion, providing a higher degree of confidentiality.</li>
                    <li><b>Access to the Deep Web:</b> Sometimes, it may be necessary to communicate via platforms not accessible by traditional browsers. TOR allows access to these deep web resources, further ensuring covert communications.</li>
                </ul>
                <h5>2. End-to-End Encryption with Signal:</h5>
                <ul>
                    <li><b>Secured Conversations:</b> Signal is built on the premise of security. All messages, voice calls, video calls, and even group chats are end-to-end encrypted. This means only the sender and the receiver have the keys to decrypt the message, ensuring that any intercepted communication remains unreadable.</li>
                    <li><b>Disappearing Messages:</b> Signal provides a feature where messages can be set to disappear after a set amount of time. This ensures that sensitive information doesn’t linger and can't be accessed later, even if a device gets compromised.</li>
                    <li><b>Open Source:</b> Signal's transparency as an open-source platform means its code can be reviewed by anyone, ensuring that there are no hidden backdoors and that its encryption methods stand up to peer scrutiny.</li>
                </ul>
                <h5>3. Protection Against State-Sponsored and Organized Cyber Attacks:</h5>
                <p>In scenarios where the adversary might be powerful entities, like organized crime groups or even nation-states, traditional communication platforms can easily be compromised. Tools like TOR and Signal are specifically designed to resist such advanced adversaries.</p>
                <h5>4. Trust in the Ecosystem:</h5>
                <p>When informants or whistleblowers trust the communication platform, they are more likely to share crucial information. By promoting and recommending the use of these tools, organizations can foster an environment of trust, making it more likely for individuals to come forward.</p>
                <p>In conclusion, when embarking on campaigns that handle delicate information, where the stakes are high and the risks even higher, it's vital to ensure that all communications are shielded from prying eyes. The TOR browser and Signal messenger app, with their robust security features, are tailor-made for such scenarios, safeguarding both the informant and the receiving organization.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Sensitivity;