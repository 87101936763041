import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_REPORTING, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import _ from 'lodash';

const Reporting = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignreports"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/attribution')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-reporting'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            reportType: _.toString(formData.getAll("reportType[]")),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_REPORTING, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/submit-campaign');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>14. Reporting</h1>
                        <p>Get in-depth insights and data based on impressions served.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={6}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-reporting">
                                        <div className="form-group">
                                            <label className="rc-block">Backend reports needed
                                                <input type="checkbox" name="reportType[]" value="Backend reports needed" defaultChecked={data && data.reportType.includes("Backend reports needed")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Automated data transfer needed (S3)
                                                <input type="checkbox" name="reportType[]" value="Automated data transfer needed" defaultChecked={data && data.reportType.includes("Automated data transfer needed")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Normal report is sufficient above
                                                <input type="checkbox" name="reportType[]" value="Normal report is sufficient above" defaultChecked={data && data.reportType.includes("Normal report is sufficient above")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Other
                                                <input type="checkbox" name="reportType[]" value="Other" defaultChecked={data && data.reportType.includes("Other")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <span id="form-error-reportType" className='form-input-error'></span>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                            <Col lg={6}>
                                <div className="reporting-image-block">
                                    <img src= {'images/reporting.jpg'} alt="Connect X" />
                                </div>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>Apart from in which application or website your banners are served, backend reports contain unique Device-ID's, Timestamps, Lat/Long coordinates and much more. Additionally its possible to automatically receive backend data based on (any) campaign activity to a personal database/bucket.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Reporting Options</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Reporting Options</div>
                <h5>CONNECTX Activation Reporting Options</h5>
                <p>Making informed decisions requires valuable data. Here, we provide you with a range of reporting options tailored to suit your needs. Let’s dive into the details of each option.</p>
                <h5>1. Standard Report</h5>
                <ul>
                    <li><b>Overview:</b> This is our basic report that provides an easy-to-understand snapshot of your campaign's performance. It's ideal for those who need a quick glance at daily metrics.</li>
                    <li><b>Metrics Included:</b> Daily impressions, clicks, and a few other basic parameters to give you a quick overview of your campaigns.</li>
                    <li><b>Formats Available:</b> Choose between CSV, Excel, or PDF formats, based on your preference.</li>
                    <li><b>Cost:</b> Free.</li>
                </ul>
                <h5>2. Backend Detailed Reports</h5>
                <ul>
                    <li><b>Overview:</b> For those who need a deeper dive into their campaign data, our Backend Reports are the answer. These are exhaustive, providing a multitude of detailed metrics.</li>
                    <li>Metrics Included: Timestamp of the event, Latitude/Longitude coordinates, Device ID, Used IP, and many more.</li>
                    <li><b>Frequency:</b> The cost of this report varies depending on how often you require it. Whether it's weekly, daily, or even hourly, we've got you covered.</li>
                    <li><b>Cost:</b> Associated with the frequency of the reports. Please contact our sales team for a detailed pricing breakdown.</li>
                </ul>
                <h5>3. Automated Data Transfer</h5>
                <ul>
                    <li><b>Overview:</b> If you have your own data storage solution and want to integrate our data directly, this option is perfect. We can push data directly to your bucket or warehouse.</li>
                    <li><b>Frequency:</b> Choose between weekly, daily, or hourly data transfers.</li>
                    <li><b>Integration:</b> Compatible with most of the popular data warehousing solutions.</li>
                    <li><b>Cost:</b> This service has an associated fee, dependent on the frequency and volume of the data transfer. Reach out to us for a customized quote.</li>
                </ul>
                <p>No matter which option you choose, our goal is to provide you with the data you need in the most convenient format. Our team is always here to assist with any questions or custom requirements you might have.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Reporting;