import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Select from 'react-select'
import { Context } from '../../components/Context';
import { CAMPAIGN_LANGUAGE_TARGETING, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import _ from 'lodash';

const Languages = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const options = [
        { value: "Afrikaans", label: "Afrikaans"},
        { value: "Albanian", label: "Albanian"},
        { value: "Arabic", label: "Arabic"},
        { value: "Armenian", label: "Armenian"},
        { value: "Basque", label: "Basque"},
        { value: "Bengali", label: "Bengali"},
        { value: "Bulgarian", label: "Bulgarian"},
        { value: "Catalan", label: "Catalan"},
        { value: "Cambodian", label: "Cambodian"},
        { value: "Chinese (Mandarin)", label: "Chinese (Mandarin)"},
        { value: "Croatian", label: "Croatian"},
        { value: "Czech", label: "Czech"},
        { value: "Danish", label: "Danish"},
        { value: "Dutch", label: "Dutch"},
        { value: "English", label: "English"},
        { value: "Estonian", label: "Estonian"},
        { value: "Fiji", label: "Fiji"},
        { value: "Finnish", label: "Finnish"},
        { value: "French", label: "French"},
        { value: "Georgian", label: "Georgian"},
        { value: "German", label: "German"},
        { value: "Greek", label: "Greek"},
        { value: "Gujarati", label: "Gujarati"},
        { value: "Hebrew", label: "Hebrew"},
        { value: "Hindi", label: "Hindi"},
        { value: "Hungarian", label: "Hungarian"},
        { value: "Icelandic", label: "Icelandic"},
        { value: "Indonesian", label: "Indonesian"},
        { value: "Irish", label: "Irish"},
        { value: "Italian", label: "Italian"},
        { value: "Japanese", label: "Japanese"},
        { value: "Javanese", label: "Javanese"},
        { value: "Korean", label: "Korean"},
        { value: "Latin", label: "Latin"},
        { value: "Latvian", label: "Latvian"},
        { value: "Lithuanian", label: "Lithuanian"},
        { value: "Macedonian", label: "Macedonian"},
        { value: "Malay", label: "Malay"},
        { value: "Malayalam", label: "Malayalam"},
        { value: "Maltese", label: "Maltese"},
        { value: "Maori", label: "Maori"},
        { value: "Marathi", label: "Marathi"},
        { value: "Mongolian", label: "Mongolian"},
        { value: "Nepali", label: "Nepali"},
        { value: "Norwegian", label: "Norwegian"},
        { value: "Persian", label: "Persian"},
        { value: "Polish", label: "Polish"},
        { value: "Portuguese", label: "Portuguese"},
        { value: "Punjabi", label: "Punjabi"},
        { value: "Quechua", label: "Quechua"},
        { value: "Romanian", label: "Romanian"},
        { value: "Russian", label: "Russian"},
        { value: "Samoan", label: "Samoan"},
        { value: "Serbian", label: "Serbian"},
        { value: "Slovak", label: "Slovak"},
        { value: "Slovenian", label: "Slovenian"},
        { value: "Spanish", label: "Spanish"},
        { value: "Swahili", label: "Swahili"},
        { value: "Swedish ", label: "Swedish "},
        { value: "Tamil", label: "Tamil"},
        { value: "Tatar", label: "Tatar"},
        { value: "Telugu", label: "Telugu"},
        { value: "Thai", label: "Thai"},
        { value: "Tibetan", label: "Tibetan"},
        { value: "Tonga", label: "Tonga"},
        { value: "Turkish", label: "Turkish"},
        { value: "Ukrainian", label: "Ukrainian"},
        { value: "Urdu", label: "Urdu"},
        { value: "Uzbek", label: "Uzbek"},
        { value: "Vietnamese", label: "Vietnamese"},
        { value: "Welsh", label: "Welsh"},
        { value: "Xhosa", label: "Xhosa"}
    ];

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [language, setLanguage] = useState();
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignlanguagetargetings"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setLanguage(res.records && res.records.language ? JSON.parse(res.records.language) : [])
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/accuracy')
    }

    const saveData = (e, type) => {        
        let formData = new FormData(document.getElementById('campaign-country'));

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            language: JSON.stringify(language),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_LANGUAGE_TARGETING, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/ip-targeting');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="connectx-block">
                        <div className="heading-text-block">
                            <h1>8. Language Targeting</h1>
                            <p>You can deliver advanced banner ads or information to phones and devices by specifically targeting their default language setting. This greatly enhances reaching a specific audience.</p>
                        </div>
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-country">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Language section</Form.Label>
                                            { data ? 
                                                <Select key={1}
                                                    defaultValue={data && data.language ? JSON.parse(data.language) : []}
                                                    isMulti
                                                    name="language[]"
                                                    options={options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(value) => setLanguage(value)}
                                                /> : 
                                                <Select key={2}
                                                    isMulti
                                                    name="language[]"
                                                    options={options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(value) => setLanguage(value)}
                                                />
                                            }
                                            <span id="form-error-language" className='form-input-error'></span>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>Targeting the default language on phones/devices enables organizations to reach highly specific global audiences easily. By combining this technique with location targeting, it becomes straightforward to deliver content, like Arabic ads, to phones in a particular location, such as Paris, making localized marketing efforts efficient and effective.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Language Targeting Explained</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Language Targeting Explained</div>
                <p>Certainly, targeting the default language on phones/devices can indeed help organizations reach highly specific global audiences. However, it's important to note that while combining this technique with location targeting can be effective, there may be cases where language targeting alone can be restrictive, and a campaign might not gain traction if location targeting is applied solely on a local level. It's essential to strike the right balance between language and location targeting for optimal results in localised intelligence efforts.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Languages;