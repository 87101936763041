import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { CAMPAIGN, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { Context } from '../../components/Context';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';

const Campaign = () => {
    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);
    const [Modal3, setModal3] = useState(false);
    const [Modal4, setModal4] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);
    const handleClose3 = () => setModal3(false);
    const handleShow3 = () => setModal3(true);
    const handleClose4 = () => setModal4(false);
    const handleShow4 = () => setModal4(true);
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaigndisplays"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = (e, type) => {
        //setTinyloader(true)
        //setActionType(type)
        navigate('/get-started')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            display: formData.get("display"),
            comments: formData.get("comments"),
        }

        setTinyloader(true)
        setActionType(type)

        fetchData(CAMPAIGN, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/campaign-budget');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>1. Campaign</h1>
                        <p>Either choose 'Display' which stands for banner campaigns, 'Social' which stands for ads shown in social networks or 'PPC' which stands for Pay-Per-Click (adwords, ads in search engines).</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign">
                                        <p>Select one of the following advertising options:</p>
                                        <div className="form-group">
                                            <label className="rc-block">'Display' for banner campaigns
                                                <input type="radio" name="display" value="'Display' for banner campaigns" defaultChecked/>
                                                <span className="checkmark radio-cm"></span>
                                            </label>
                                            <label className="rc-block">'Social' for ads on social networks
                                                <input type="radio" name="display" value="'Social' for ads on social networks"  defaultChecked={data && data.display.includes("'Social' for ads on social networks")}/>
                                                <span className="checkmark radio-cm"></span>
                                            </label>
                                            <label className="rc-block">'PPC' for Pay-Per-Click advertisements (such as AdWords or search engine ads)
                                                <input type="radio" name="display" value="'PPC' for Pay-Per-Click advertisements (such as AdWords or search engine ads)" defaultChecked={data && data.display.includes("'PPC' for Pay-Per-Click advertisements (such as AdWords or search engine ads)")}/>
                                                <span className="checkmark radio-cm"></span>
                                            </label>
                                            <span id="form-error-display" className='form-input-error' ></span>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control name="comments" as="textarea" rows={3} defaultValue={data && data.comments}/>
                                            <span id="form-error-comments" className='form-input-error' ></span>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            {/* <p>CONNECTX allows it's users to deploy sensitive publicity campaigns in virtually any country. The provided information will reach phones/devices in (very) specific area's simply by the fact that almost all of the worlds' population uses the internet on a daily basis.</p> */}
                            <p>CONNECTX enables users to launch targeted publicity campaigns in almost any country. The information will reach devices in specific areas, leveraging the fact that a vast majority of the global population accesses the internet daily.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Banner Guidelines</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow2} disabled><FontAwesomeIcon icon="fa-solid fa-circle-info" /> PPC Guidelines</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow3} disabled><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Social Ads Guidelines</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow4} disabled><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Fictional Case Studies</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Banner Guidelines</div>
                <p><b>Global Banner Information Dissemination: Bridging the Divide</b></p>
                <p>Welcome to the era of unparalleled connectivity and information dissemination! In today's interconnected world, the power of the internet allows for seamless communication and outreach across continents. Banners, those unassuming rectangular graphics that adorn websites and apps, have become a formidable tool for serving information that ranges from urgent security matters to promoting social causes. This page elaborates on how banners can be strategically served across diverse corners of the globe, leveraging the extensive presence of ad slots on websites and apps.</p>
                <h5>1. A Borderless Information Highway:</h5>
                <p>The internet knows no geographical boundaries, making it an ideal platform for sharing information on a global scale. Banners, the digital billboards of the online realm, can be embedded within websites and apps, thereby reaching audiences irrespective of their physical location. This means that even if a website caters to a specific region or demographic, the presence of ad slots ensures that banners can still be served and noticed.</p>
                <h5>2. Empowering Communication Channels:</h5>
                <p>Banners possess the unique ability to deliver a wide range of messages. From urgent appeals such as offering rewards for information on known terrorists to championing just causes and promoting social initiatives, banners serve as a versatile communication channel. They are designed to capture attention and communicate important information succinctly, making them a powerful tool for influencing public opinion and generating engagement.</p>
                <h5>3. Harnessing the Ubiquitous Ad Slot:</h5>
                <p>The key to global banner dissemination lies in the ubiquity of ad slots across websites and apps. Approximately 90% of websites and applications feature ad slots, providing a vast canvas for serving banners. This pervasive presence ensures that no matter how niche a website's audience might be, the potential for reaching diverse segments of the population remains substantial.</p>
                <h5>4. Diverse Applications, Common Mechanism:</h5>
                <p>The beauty of banner dissemination lies in its uniform mechanism. Whether it's a mainstream news website or a specialized app catering to a specific interest group, the process of embedding and serving banners remains consistent. This streamlined approach democratizes the ability to share information, irrespective of the specific context or audience of the hosting platform.</p>
                <h5>5. Navigating Sensitive Information:</h5>
                <p>Banners can be used to address sensitive topics and urgent matters. Governments, law enforcement agencies, and social organizations can utilize banners to seek information, request assistance, or spread awareness on matters of national and global significance. This adaptability is particularly vital in times of crisis, allowing vital information to be shared swiftly and effectively.</p>
                <p>In the digital age, banners have emerged as a global tool for disseminating information to diverse audiences. With their presence on the majority of websites and apps, these unassuming graphical elements have the power to transcend geographical boundaries and influence public perception. From security matters to social causes, banners serve as a common language of information sharing in a world where connectivity knows no limits.</p>
                <p>Embrace the potential of banners as a conduit of global communication, where even the most specific of platforms can contribute to the collective dissemination of knowledge and ideas, fostering understanding and empowerment across continents.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">PPC Guidelines</div>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal3} onHide={handleClose3} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Social Ads Guidelines</div>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal4} onHide={handleClose4} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Fictional Case Studies</div>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Campaign;