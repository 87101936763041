import { Button, Spinner } from "react-bootstrap";
import AWS from "aws-sdk";
import moment from 'moment/moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const displayError = (errors) => {
    var elements = document.getElementsByClassName("form-input-error");

    for (var i = 0; i < elements.length; i++) {
        elements[i].innerHTML = "";
    }

    if (Object.keys(errors).length > 0) {
        errors.forEach((ele) => {
            document.getElementById("form-error-" + ele.param).innerHTML = ele.msg
        })
    }
}

export const ActionButton = (props) => {
    return (
        <div className="action-buttons-block">
            <Button role="button" className="btn btn-preview" onClick={(e) => props.previousData(e, "previous")} disabled={props.tinyloader}>
                {props.tinyloader && props.actionType ==="previous" && <Spinner animation="grow" size="sm" />} Previous
            </Button>
            <Button role="button" className="btn btn-primary" onClick={(e) => props.saveData(e, 'saveNext')} disabled={props.tinyloader}>
                {props.tinyloader && props.actionType ==="saveNext" && <Spinner animation="grow" size="sm" />} Next
            </Button>
            <Button role="button" variant="save" onClick={(e) => props.saveData(e, 'save')} disabled={props.tinyloader}>
                {props.tinyloader && props.actionType ==="save" && <Spinner animation="grow" size="sm" />} Save Setup
            </Button>
        </div>
    )
}

export const DownloadButton = (props) => {
    return (<a className="text-danger ms-3" href={process.env.REACT_APP_AWS_S3_URL + props.url} target="_blank" rel="noreferrer" download><FontAwesomeIcon icon="fa-solid fa-download" /></a>)
}

export const PageLoader = (status) => {
    if(status){
        document.getElementById("full-page-loader").classList.remove('d-none');
    }else{
        document.getElementById("full-page-loader").classList.add('d-none');
    }
}

export const uploadFileToAWSBucket = async (e) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
    const REGION = process.env.REACT_APP_AWS_REGION;

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    let fileName =  moment().unix() + "-" + e.target.files[0].name

    const params = {
        Bucket: S3_BUCKET,
        Key: fileName,
        Body: e.target.files[0],
    };

    var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
            // console.log(
            //     "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            // );
        })
        .promise();

    await upload.then((err, data) => {
        console.log(err);
        console.log("File uploaded successfully.");
    });

    return fileName;
};