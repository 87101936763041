import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_IP_TARGETING, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, DownloadButton, PageLoader, displayError, uploadFileToAWSBucket } from '../../components/Helper';
import _ from 'lodash';

const IPTargeting = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [ipData, setIPData] = useState(null);
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaigniptargetings"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
                setIPData(res.records.fileUrl)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/language-targeting')
    }

    const saveData = (e, type) => {        
        let formData = new FormData(document.getElementById('campaign-country'));

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            fileUrl: ipData,
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_IP_TARGETING, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/rich-media');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    const uploadFile = async (e) => {
        setFileUploadLoader(true)
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setIPData(file)
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="connectx-block">
                        <div className="heading-text-block">
                            <h1>9. IP Targeting</h1>
                            <p>IP Targeting in programmatic display advertising enables the delivery of banners to phones and devices that access the internet through specific IP ranges, ensuring precise audience targeting.</p>
                        </div>
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-country">
                                        <div className="form-group">
                                            <label className="form-label">Upload IP ranges <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span></label>
                                            <div className="double-field-block">
                                                <div className="file-upload-block">
                                                    <Form.Control type="file" onChange={(e) => uploadFile(e)}/>
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload IP ranges</Button>
                                                </div>
                                                { ipData && <DownloadButton url={ipData} /> }
                                                <span id="form-error-fileUrl" className='form-input-error' ></span>
                                            </div>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>Targeting the default language on phones/devices enables organizations to reach highly specific global audiences easily. By combining this technique with location targeting, it becomes straightforward to deliver content, like Arabic ads, to phones in a particular location, such as Paris, making localized marketing efforts efficient and effective.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> IP Targeting Explained</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">IP Targeting Explained</div>
                <h5>Unlocking Global Precision with IP Targeting</h5>
                <p>Welcome to CONNECTX Activation, your gateway to precision in the world of programmatic advertising. With our cutting-edge IP Targeting tool, you can now tap into the power of specific IP ranges, a game-changing feature that can revolutionize the way you connect with your target audience.</p>
                <h5>Why IP Targeting Matters</h5>
                <p>In today's digital landscape, countries and their security-related entities often rely on proprietary satellites to enable and carry specific IP ranges. These ranges are used for various purposes, including communication, data transmission, and security. Recognising the importance of these IP ranges, we've developed a tool that allows specialized organizations to harness their potential and reach specific audiences worldwide.</p>
                <h5>Global Reach, Targeted Impact</h5>
                <p>CONNECTX Activation's IP Targeting tool empowers you to serve your message precisely where it matters most. By uploading an Excel spreadsheet containing the IP addresses you want to target, you gain the ability to engage with audiences in a way that's unparalleled in its accuracy and effectiveness.</p>
                <h5>Here's how it works:</h5>
                <ul>
                    <li><b>Single IPs</b>: Simply input the individual IPs you wish to target in your spreadsheet, and our platform will ensure your message reaches those specific addresses.</li>
                    <li><b>IP Ranges</b>: If you're targeting a range of IP addresses, it's just as straightforward. Upload the starting IP address in column A and the end of the range in column B of your spreadsheet. Our system will take care of the rest, delivering your content precisely where it's needed.</li>
                </ul>
                <h5>Your Global Advertising Strategy, Perfected</h5>
                <p>At CONNECTX Activation, we understand that success in advertising is all about reaching the right people at the right time. With IP Targeting, you can take your campaigns to the next level, ensuring that your message resonates with precision across the globe.</p>
                <p>Start leveraging the power of IP Targeting on CONNECTX Activation and witness the transformation in your intelligence efforts. Reach out to your desired audience, wherever they may be, and make every interaction count.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default IPTargeting;