import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SavedCampaigns from '../../modules/saved-campaigns/saved-campaigns';
import { Context } from '../../components/Context';
import { CAMPAIGN_START, fetchData } from '../../components/Service';

const GetStarted = () => {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);
    const [Modal3, setModal3] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);
    const handleClose3 = () => setModal3(false);
    const handleShow3 = () => setModal3(true);

    const saveData = (e) => {
        let data = {
            userId: context && context.id
        }

        setTinyloader(true);

        fetchData(CAMPAIGN_START, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                setContext(prevValue => ({
                    ...prevValue,
                    campaign: { ...prevValue.campaign, id: res.records.id }
                }));

                navigate(`/${res.records.formNamePath}`);
            } else {
                //displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper mb-5">
                    <div className="heading-text-block">
                        <h1>Get started</h1>
                        <p>Launch a campaign in virtually any country</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={12}>
                                <div className="action-buttons-block">
                                    <Button type="button" variant="primary" onClick={saveData} disabled={tinyloader}>
                                        {tinyloader && <Spinner animation="grow" size="sm" />} Launch Campaign
                                    </Button>
                                    {/* <a className="btn btn-primary" href="https://dsp.targetoo.com/inventory-forecasting">forecasting</a> */}
                                    <Button variant="primary" className='disabled'>Reporting</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> What is CONNECTX Activation?</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow2}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Campaign Options</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow3}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Reach per Country</Button>
                    </div>
                </div>
            </Container>
            <SavedCampaigns></SavedCampaigns>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
            {/* <Modal.Title>What is CONNECTX Activation?</Modal.Title> */}
            <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="model-logo"><img src={'images/logo.png'} alt="Connect X"/></div> */}
                <div className="main-heding">What is CONNECTX Activation?</div>
                <p>CONNECTX Activation is an innovative online platform that empowers users to effortlessly submit programmatic display campaigns, Pay per Click (PPC) campaigns, and Social campaigns. If you're looking to reach millions of potential customers or supporters for a specific cause, CONNECTX Activation is your go-to solution. With our user-friendly interface and cutting-edge programmatic advertising technology, sharing information has never been easier or more cost-effective.</p>
                <h5>The Power of Programmatic Advertising:</h5>
                <p>At CONNECTX Activation, we believe in the immense power of programmatic advertising. This revolutionary approach allows you to stimulate millions of people to engage with your content and share information on your cause efficiently and affordably. Gone are the days of relying solely on traditional media, which can be expensive and lack the precise targeting options offered by programmatic advertising.</p>
                <h5>Cost-Effective Campaigns:</h5>
                <p>Programmatic advertising enables you to make the most out of your budget. By using data-driven algorithms and automated processes, we optimize your campaigns to deliver maximum results while keeping costs down. This way, you can focus on your message and impact, without worrying about extravagant advertising expenses.</p>
                <h5>Precise Targeting Options:</h5>
                <p>The ability to precisely target your audience is one of the key advantages of programmatic advertising. At CONNECTX Activation, we offer a wide range of targeting options, allowing you to reach the right people at the right time. Whether you want to target a specific demographic, location, or interest group, our platform enables you to do so with just a click of a button.</p>
                <h5>Easy Campaign Submission:</h5>
                <p>Submitting your programmatic display campaign, PPC campaign, or Social campaign on CONNECTX Activation is a breeze. Our user-friendly interface guides you through the entire process step-by-step, making it accessible for both seasoned marketers and newcomers alike. No complex technical knowledge is required – just your creativity and dedication to your cause.</p>
                <h5>Unleash the Potential:</h5>
                <p>When you choose CONNECTX Activation, you unlock the full potential of programmatic advertising to raise awareness, drive engagement, and achieve your campaign goals. Join our community of successful advertisers who have harnessed the power of programmatic advertising to make a real impact on their audiences and causes.</p>
                <h5>Are you ready to connect with millions and make a difference? </h5>
                <p>Sign up now on CONNECTX Activation and launch your programmatic display campaign, PPC campaign, or Social campaign with ease and precision. Together, let's revolutionize the way information is shared and bring your message to the world.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Campaign Options</div>
                <h5>Introducing CONNECTX Activation: Empowering Global Publicity Campaigns</h5>
                <p>We are delighted to present CONNECTX Activation, a revolutionary platform designed to empower its users in deploying highly effective and targeted publicity campaigns in any country across the globe. Our platform leverages advanced advertising techniques to stimulate people into providing valuable information on any given topic or situation, rapidly and efficiently.</p>
                <h5><b>Campaign 1:</b> Programmatic Display Advertising with a DSP</h5>
                <p>Programmatic display advertising is a cutting-edge technique that utilizes a Demand-Side Platform (DSP) to automate the process of buying and optimizing digital ad space. CONNECTX Activation employs this method to serve banners effectively and economically, reaching a vast audience on various websites and apps. This approach ensures that your message is displayed to the right audience at the right time, maximizing the potential for information gathering.</p>
                <h5><b>Campaign 2:</b> Advertising on Social Media Networks</h5>
                <p>Social media networks have become an integral part of people's lives globally, making them a prime channel for publicity campaigns. CONNECTX Activation facilitates advertising on popular social media platforms like Facebook, Twitter, Instagram, and more. By strategically targeting specific demographics and interests, your campaign can resonate with the intended audience, encouraging them to share relevant information effortlessly.</p>
                <h5><b>Campaign 3:</b> Targeted PPC Advertising like AdWords</h5>
                <p>Pay-Per-Click (PPC) advertising, such as Google AdWords, has proven to be an efficient and cost-effective way to drive traffic to your desired destination. CONNECTX Activation streamlines this process, enabling you to create compelling ads that will appear on relevant websites and search engines. In countries where Google might not be the primary search engine, our platform allows you to adapt and utilize locally popular search engines, guaranteeing that your campaign reaches the right respondents.</p>
                <h5>The Power of CONNECTX Activation</h5>
                <p>CONNECTX Activation's primary goal is to inspire engagement and garner insights from a diverse audience, efficiently and economically. By utilizing the three campaign strategies described above, our platform offers several key advantages:</p>
                <ul>
                    <li><b>Cost-effectiveness:</b> With automated advertising and optimized bidding strategies, you can run campaigns within your budget, ensuring each impression and click is utilized efficiently.</li>
                    <li><b>Speed and Agility:</b> Our platform enables swift campaign deployment, reaching your target audience promptly and gathering information in real-time.</li>
                    <li><b>Precise Targeting:</b> Leveraging advanced data analytics, CONNECTX Activation facilitates laser-focused targeting, ensuring that your campaign reaches the right people with relevant interests and behaviors.</li>
                    <li><b>Global and Local Reach:</b> Whether your campaign is intended for a worldwide audience or specific countries, CONNECTX Activation tailors your outreach accordingly, considering local preferences and search engines.</li>
                </ul>
                <p>In conclusion, CONNECTX Activation is your go-to platform for executing fast, targeted, and efficient publicity campaigns across the globe. Through programmatic display advertising, social media network outreach, and PPC campaigns, we help you stimulate people into providing valuable information on any given topic or situation, all while maintaining cost-effectiveness. Embrace the power of CONNECTX Activation and elevate your publicity campaigns to new heights.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal3} onHide={handleClose3} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Reach per Country</div>
                <h5>Introducing CONNECTX Activation: Connecting Your Campaigns Globally</h5>
                <p>We are pleased to introduce CONNECTX Activation, a revolutionary platform that harnesses the power of the internet to expand the reach of your publicity campaigns to audiences all around the world. In this digital age, the internet has become a global conduit, transcending geographical boundaries and enabling seamless communication across borders. By leveraging the vast network of users from diverse countries, CONNECTX Activation empowers your campaigns to resonate with international audiences effortlessly.</p>
                <h5>Global Reach Through Adslots, Social Media, and Search Engines</h5>
                <p>At CONNECTX Activation, we understand that the internet has become an integral part of people's lives worldwide. Almost every app, site, social media platform, or search engine nowadays features what is known as an 'adslot,' an advertising space where campaigns can be displayed. As a result, any publicity campaign submitted on our platform can potentially be disseminated across the globe.</p>
                <p>Whether your campaign targets specific countries or a broader international audience, the beauty of CONNECTX Activation lies in the fact that we capitalize on the ubiquity of internet usage. Even when your ads are placed on apps, sites, social media platforms, or search engines that are specific to certain countries, there is a high probability that individuals from various nations will interact with your content.</p>
                <h5>Embracing Diversity - Niche Platforms and Global Impact</h5>
                <p>One of the most intriguing aspects of CONNECTX Activation is its ability to cater to highly specific and niche markets while simultaneously achieving global exposure. With the internet's plethora of platforms catering to unique interests and preferences in various countries, we embrace this diversity to enhance the impact of your campaigns.</p>
                <p>Suppose you are targeting a niche market in a specific country, where users predominantly engage with localized apps, websites, or social media networks. In that case, CONNECTX Activation ensures that your campaign reaches these audiences with precision. Conversely, for campaigns with broader appeal, we employ a strategic approach to connect with a diverse global audience through international platforms.</p>
                <h5>Simplicity Meets Global Outreach</h5>
                <p>Our platform is designed to make your experience hassle-free and seamless. By utilizing CONNECTX Activation, you no longer need to navigate complex international advertising processes or deal with multiple agencies to reach diverse audiences. We have simplified the process, ensuring that your campaigns run effortlessly and efficiently across borders.</p>
                <h5>Privacy and Customization</h5>
                <p>While CONNECTX Activation allows you to achieve global reach, we prioritize privacy and customization. You have full control over the regions where your campaigns will be active, enabling you to respect local laws, preferences, and sensitivities. Our platform empowers you to tailor your message to resonate with each specific audience while adhering to cultural nuances and regulations.</p>
                <h5>Join CONNECTX Activation - Unlock the World</h5>
                <p>In conclusion, CONNECTX Activation presents an unprecedented opportunity to connect with audiences all over the world. We have harnessed the power of the internet to transcend boundaries and bring your campaigns to diverse cultures and markets. From niche platforms in specific countries to social media giants and widely used search engines, we unlock the potential for your campaigns to leave a global impact.</p>
                <p>Embrace this new era of borderless advertising with CONNECTX Activation and watch your campaigns thrive on an international stage. Together, we will transform your visions into worldwide success, one click at a time.</p>
                <h5>Welcome to CONNECTX Activation - Connecting Your Campaigns Globally.</h5>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default GetStarted;