import { Container } from 'react-bootstrap';
import CampaignData from '../../components/CampaignData';

const SavedCampaigns = () => {
    return (
        <>
        {/* <Header></Header> */}
        {/* <div className="main-page-wrapper"> */}
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>Saved Campaigns</h1>
                        <p>Your saved campaigns list</p>
                    </div>
                    <div className="connectx-block">
                        <CampaignData viewType="user"/>
                    </div>
                    {/* <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Submitted your campaign, and now?</Button>
                    </div> */}
                </div>
            </Container>
        {/* </div> */}
        {/* <Footer></Footer> */}

        {/* <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block small-cust-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Delete Campaign</div>
                <div className="delete-block">
                    <p>Are you sure, you want to delete campaign?</p>
                    <div className="delete-blk-btn">
                        <Button variant="preview" className="btn-medium me-2" onClick={handleClose2}>Cancel</Button>
                        <Button variant="primary" className="btn-medium">Delete</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal> */}
        </>
    );
};

export default SavedCampaigns;