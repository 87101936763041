import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_DATA_DERIVED, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, DownloadButton, PageLoader, displayError, uploadFileToAWSBucket } from '../../components/Helper';
import _ from 'lodash';

const DataDerived = () => {
    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [deviceData, setDeviceData] = useState(null);
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaigndataderiveds"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
                setDeviceData(res.records.fileUrl)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/rich-media')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-data-derived'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            derivedType: _.toString(formData.getAll("derivedType[]")),
            fileUrl: deviceData,
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_DATA_DERIVED, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/sensitivity');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    const uploadFile = async (e) => {
        setFileUploadLoader(true)
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setDeviceData(file)
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>11. Upload device ids and usage of data derived from past campaigns</h1>
                        <p>Choose if you want your banner to be served/shown to people that interacted with similar banners in the past and/or upload your own list of Device IDs.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-data-derived">
                                        <div className="form-group">
                                            <label className="rc-block">Devices that clicked on a similar ad, in same country
                                                <input type="checkbox" name="derivedType[]" value="Devices that clicked on a similar ad in same country" defaultChecked={data && data.derivedType.includes("Devices that clicked on a similar ad in same country")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Devices that shared in same country
                                                <input type="checkbox" name="derivedType[]" value="Devices that shared in same country" defaultChecked={data && data.derivedType.includes("Devices that shared in same country")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Other
                                                <input type="checkbox" name="derivedType[]" value="Other" defaultChecked={data && data.derivedType.includes("Other")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <span id="form-error-derivedType" className='form-input-error' ></span>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Upload lists of device id's (Txt, Word, Excel, Number files)</label>
                                            <div className="double-field-block">
                                                <div className="file-upload-block">
                                                    <Form.Control type="file" onChange={(e) => uploadFile(e)} />
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload lists of device id's</Button>
                                                </div>
                                                { deviceData && <DownloadButton url={deviceData} /> }
                                            </div>
                                            <span id="form-error-fileUrl" className='form-input-error' ></span>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>Somebody who clicked on a similar banner in the past can potentially be a new source of information. By activating such data-sets the changes of a positive outcome of your campaign can increase.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Past Campaign Data & Uploading Device IDs</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Past Campaign Data & Uploading Device IDs</div>
                {/* <h5>Harness the Power of Historical Data for Your Campaigns</h5>
                <p><b>Dive Deeper into Your Audience Insights</b></p> */}
                <h5>Understanding Past Engagement:</h5>
                <p>By deploying campaigns in the past, we have gathered a wealth of data about the devices that interacted with our banners. This information offers invaluable insights into the behaviors and preferences of your audience.</p>
                <h5>Customize with Your Data:</h5>
                <p>If you've collected proprietary data in the form of device IDs from previous campaigns or other sources, you can seamlessly integrate them into your next campaign with us. Just upload the data, and we'll ensure that it's integrated into our audience targeting strategies.</p>
                <h5>A Track Record of Intelligence:</h5>
                <p>Users who have clicked on our banners in the past aren't just random clicks; they've provided intelligent interactions and valuable feedback. By tapping into this pre-existing data, you’re not just reaching an audience, but a community that has shown a proclivity for engagement.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default DataDerived;