export const ENDPOINT = process.env.REACT_APP_ENDPOINT

export const LOGIN = "/auth/login"
export const AUTH_USER_DETAIL = "/auth/auth-user-detail"
export const CHANGE_PASSWORD = "/auth/change-password"

export const USER_LIST = "/users/users-list"
export const USER_CREATE = "/users/users-create"
export const USER_DETAIL = "/users/users-details"
export const USER_UPDATE = "/users/users-update"
export const USER_DELETE = "/users/users-delete"

export const DASHBOARD_STATISTICS = "/users/dashboard-statistics"

export const CAMPAIGN_LIST = "/campaign/campaign-list"
export const CAMPAIGN_DETAIL_FULL = "/campaign/campaign-detail-full"
export const GET_CAMPAIGN_DETAIL = "/campaign/campaign-detail"
export const CAMPAIGN_START = "/campaign/start-campaign"
export const CAMPAIGN = "/campaign/campaign"
export const CAMPAIGN_BUDGET = "/campaign/campaign-budget"
export const CAMPAIGN_FUNDING = "/campaign/campaign-funding"
export const CAMPAIGN_BANNER = "/campaign/campaign-banner"
export const CAMPAIGN_RUNTIME = "/campaign/campaign-runtime"
export const CAMPAIGN_COUNTRIES = "/campaign/campaign-countries"
export const CAMPAIGN_ACCURACY = "/campaign/campaign-accuracy"
export const CAMPAIGN_LANGUAGE_TARGETING = "/campaign/campaign-language-targeting"
export const CAMPAIGN_IP_TARGETING = "/campaign/campaign-ip-targeting"
export const CAMPAIGN_RICH_MEDIA = "/campaign/campaign-rich-media"
export const CAMPAIGN_DATA_DERIVED = "/campaign/campaign-data-derived"
export const CAMPAIGN_SENSIVITY = "/campaign/campaign-sensitivity"
export const CAMPAIGN_ATTRIBUTION = "/campaign/campaign-attribution"
export const CAMPAIGN_REPORTING = "/campaign/campaign-reporting"
export const CAMPAIGN_SUBMIT = "/campaign/campaign-submissions"
export const CAMPAIGN_DELETE = "/campaign/campaign-delete"

export const fetchData = async (url, method, data, token, process, res, err, processimg = false) => {
    let headers = {
        'Accept': 'application/json',
    }

    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }

    if (token) {
        let TOKEN = localStorage.getItem("__cxtoken");
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }
  
    await fetch(`${ENDPOINT}${url}`, request).then((response) => processimg === "text" ? response.text() : (processimg === "blob"? response.blob(): response.json() )).then((json) => {
        if (json.message === "unauthenticated") {
            localStorage.removeItem("__cxtoken");
        } else {
            res(json)
        }
    })
    .catch((error) => { console.log(error) });
}