import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import moment from 'moment/moment';
import { useEffect } from 'react';

const TimeSlider = (props) => {
    useEffect(() => {
        var slider = document.getElementById(`slider-${props.sliderId}`);

        if(slider && slider.noUiSlider){
            slider.noUiSlider.destroy();
        }

        noUiSlider.create(slider, {
            start: [moment.duration(props.sliderValue[0]).asMinutes(), moment.duration(props.sliderValue[1]).asMinutes()],
            connect: true,
            step: 15,
            tooltips: [false, false],
            range: {
                'min': 0,
                'max': 1439
            },
            format: {
                to: function ( value ) {
                    return moment().startOf('day').add(value, 'minutes').format('HH:mm');
                },
                from: function ( value ) {
                    return value;
                }
            }
        });

        var snapValues = [
            document.getElementById(`slider-${props.sliderId}-value-lower`),
            document.getElementById(`slider-${props.sliderId}-value-upper`)
        ];
        
        slider.noUiSlider.on('update', function (values, handle) {
            snapValues[handle].innerHTML = values[handle];
        });
    }, [props])

    return (
        <>
            <div id={`slider-${props.sliderId}`}></div>
            <div className='time-slider-value'><span id={`slider-${props.sliderId}-value-lower`}>20:45</span> - <span id={`slider-${props.sliderId}-value-upper`}>14:15</span></div>
        </>
    );
};

export default TimeSlider;