import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import HeaderLogin from '../../components/HeaderLogin';
import { LOGIN, fetchData } from '../../components/Service';
import { Context } from '../../components/Context';
import { displayError } from '../../components/Helper';

const Login = () => {
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState('')
    const [context, setContext] = useContext(Context)
    
    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }

    const loginSubmission = (e) => {
        let formData = new FormData(document.getElementById('login-form'));
        let data = {
            email: formData.get("email"),
            password: formData.get("password"),
        }

        setTinyloader(true);

        fetchData(LOGIN, 'POST', data, false, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                localStorage.setItem('__cxtoken', res.records.accessToken)
                setContext(res.records)

                if (res.records.role === 'admin') {
                    navigate('/admin/dashboard');
                } else {
                    navigate(`/get-started`);
                }
            } else {
                setData(res)
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <HeaderLogin></HeaderLogin>
        <div className="login-wrapper">
            <video id="myVideo" autoPlay muted loop poster="images/connectx-poster.jpg">
                <source src="https://connectx-image.s3.amazonaws.com/connectx-video.mp4" type="video/mp4"/>
            </video>
            <div className="login-section">
                <img src={'images/logo-color.png'} alt="Connect X"/>
                <h1>Login</h1>
                { data && data.status === "error" && data.message && <span className="error-message">{data.message}</span> }
                <Form className="custom-form-block" id="login-form">
                    <Form.Group className="form-group">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="text" name="email" onKeyUp={changeHandler} />
                        <span id="form-error-email" className='form-input-error' ></span>
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" onKeyUp={changeHandler}/>
                        <span id="form-error-password" className='form-input-error'></span>
                    </Form.Group>
                    <Button variant="primary" type="button" onClick={loginSubmission} disabled={tinyloader}>
                        {tinyloader && <Spinner animation="grow" size="sm" />} Submit
                    </Button>
                </Form>
                <p><FontAwesomeIcon icon="fa-solid fa-lock" /> Secured Network</p>
            </div>
        </div>
        </>
        
    );
};

export default Login;
