import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_ATTRIBUTION, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import _ from 'lodash';

const Attribution = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignattributions"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/sensitivity')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-attribution'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            attributionType: _.toString(formData.getAll("attributionType[]")),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_ATTRIBUTION, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/reporting');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>13. Level of Attribution</h1>
                        <p>Due to sensitivity of the campaigns CONNECTX can deploy, we offer various levels of discretion in regards to information disclosed to DSP/Vendor.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-attribution">
                                        <p>DSP/Vendor can obtain knowledge of</p>
                                        <div className="form-group">
                                            <label className="rc-block">Partner <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Partner" defaultChecked={data && data.attributionType.includes("Partner")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Client <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Client" defaultChecked={data && data.attributionType.includes("Client")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Shielded entity must be used (e.g. Anthalon.com) <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Shielded entity must be used" defaultChecked={data && data.attributionType.includes("Shielded entity must be used")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Un-traceable entity must be used (e.g. Publisecur.com) <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Un-traceable entity must be used" defaultChecked={data && data.attributionType.includes("Un-traceable entity must be used")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Simulated identity <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Simulated identity" defaultChecked={data && data.attributionType.includes("Simulated identity")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Proton hosting, paid with crypto <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Proton hosting paid with crypto" defaultChecked={data && data.attributionType.includes("Proton hosting paid with crypto")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Other <span className="info-icon-block"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></span>
                                                <input type="checkbox" name="attributionType[]" value="Other" defaultChecked={data && data.attributionType.includes("Other")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <span id="form-error-attributionType" className='form-input-error'></span>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        {/* <div className="sec-foot-content">
                            <p>By categorising your campaign accordingly, only accepting distributing partners will be presented the campaign.</p>
                        </div> */}
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Aggregation Additional Info</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Aggregation Additional Info</div>
                <h5>Discrete Methods of Funding for Programmatic Display Campaigns</h5>
                <p>In the realm of programmatic display campaigns, the manner in which funds are transferred and the extent to which parties are aware of each other can be of paramount importance. This is especially true in scenarios where advertisers and publishers wish to maintain a level of confidentiality or privacy. Below are discrete methods of funding and communication that cater to different levels of anonymity and security:</p>
                <h5>1. Complete Knowledge of Partner and Client: </h5>
                <ul>
                    <li>This is the most open form of funding. Here, publishers are fully aware of both the Partner and the client responsible for funding the campaign. </li>
                    <li>Benefits: Transparent and straightforward transactions, easier troubleshooting, and direct communication.</li>
                </ul>
                <h5>2. Knowledge of Only Partner and Self-funding:</h5>
                <ul>
                    <li>In this method, publishers are only made aware of the Partner and the fact that they're funding the campaign.</li>
                    <li>Benefits: Limited exposure of the client's identity, preserving a degree of anonymity.</li>
                </ul>
                <h5>3. Shield Entity Communication via Anthalon:</h5>
                <ul>
                    <li>Here, publishers only interact with a shield entity like [www.anthalon.com](http://www.anthalon.com), which acts as a mediator, ensuring no direct contact between the publisher and the main advertiser.</li>
                    <li>Benefits: Increased privacy, reduced chances of bias or negotiation issues due to the anonymity of the main advertiser.</li>
                </ul>
                <h5>4. Shielded and Non-traceable Entity via Publisecur:</h5>
                <ul>
                    <li>In this method, publishers can only communicate through a platform like [www.publisecur.com](http://www.publisecur.com), which ensures no traces back to the main client.</li>
                    <li>Benefits: High level of anonymity and security, ensuring complete confidentiality.</li>
                </ul>
                <h5>5. CONNECTX Creation:</h5>
                <ul>
                    <li>A completely new entity is built exclusively for the campaign. Once the campaign is over, this entity might be dissolved.</li>
                    <li>Benefits: Tailored campaign security, ensuring there's no association with previous campaigns or known advertisers.</li>
                </ul>
                <h5>6. Shielded, Non-traceable Entity with Anonymous Funding:</h5>
                <ul>
                    <li>Publishers communicate only with a shielded and non-traceable entity. Additionally, funds are provided anonymously, for instance, using cryptocurrencies.</li>
                    <li>Benefits: Provides the highest level of confidentiality and security. The use of cryptocurrencies ensures transactional privacy.</li>
                </ul>
                <h5>Conclusion</h5>
                <p>The method of funding and communication chosen for a programmatic display campaign will largely depend on the privacy needs of the advertiser and the nature of the campaign. As the digital advertising ecosystem evolves, these discrete methods offer varying levels of security, ensuring that both publishers and advertisers can maintain desired levels of confidentiality while still achieving their marketing objectives.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Attribution;