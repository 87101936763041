import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form, Table } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Context } from '../../components/Context';
import { CAMPAIGN_RUNTIME, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import TimeSlider from '../../components/TimeSlider';

const Runtime = () => {

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);

    const [monSlider, setMonSlider] = useState(["00:00", "23:59"])
    const [tueSlider, setTueSlider] = useState(["00:00", "23:59"])
    const [wedSlider, setWedSlider] = useState(["00:00", "23:59"])
    const [thuSlider, setThuSlider] = useState(["00:00", "23:59"])
    const [friSlider, setFriSlider] = useState(["00:00", "23:59"])
    const [satSlider, setSatSlider] = useState(["00:00", "23:59"])
    const [sunSlider, setSunSlider] = useState(["00:00", "23:59"])

    const minutes = [
        {label: "00",value: "00",},
        {label: "01",value: "01",},
        {label: "02",value: "02",},
        {label: "03",value: "03",},
        {label: "04",value: "04",},
        {label: "05",value: "05",},
        {label: "06",value: "06",},
        {label: "07",value: "07",},
        {label: "08",value: "08",},
        {label: "09",value: "09",},
        {label: "10",value: "10",},
        {label: "11",value: "11",},
        {label: "12",value: "12",},
        {label: "13",value: "13",},
        {label: "14",value: "14",},
        {label: "15",value: "15",},
        {label: "16",value: "16",},
        {label: "17",value: "17",},
        {label: "18",value: "18",},
        {label: "19",value: "19",},
        {label: "20",value: "20",},
        {label: "21",value: "21",},
        {label: "22",value: "22",},
        {label: "23",value: "23",},
    ];
    const seconds = [
        {label: "00",value: "00",},
        {label: "01",value: "01",},
        {label: "02",value: "02",},
        {label: "03",value: "03",},
        {label: "04",value: "04",},
        {label: "05",value: "05",},
        {label: "06",value: "06",},
        {label: "07",value: "07",},
        {label: "08",value: "08",},
        {label: "09",value: "09",},
        {label: "10",value: "10",},
        {label: "11",value: "11",},
        {label: "12",value: "12",},
        {label: "13",value: "13",},
        {label: "14",value: "14",},
        {label: "15",value: "15",},
        {label: "16",value: "16",},
        {label: "17",value: "17",},
        {label: "18",value: "18",},
        {label: "19",value: "19",},
        {label: "20",value: "20",},
        {label: "21",value: "21",},
        {label: "22",value: "22",},
        {label: "23",value: "23",},
        {label: "24",value: "24",},
        {label: "25",value: "25",},
        {label: "26",value: "26",},
        {label: "27",value: "27",},
        {label: "28",value: "28",},
        {label: "29",value: "29",},
        {label: "30",value: "30",},
        {label: "31",value: "31",},
        {label: "32",value: "32",},
        {label: "33",value: "33",},
        {label: "34",value: "34",},
        {label: "35",value: "35",},
        {label: "36",value: "36",},
        {label: "37",value: "37",},
        {label: "38",value: "38",},
        {label: "39",value: "39",},
        {label: "40",value: "40",},
        {label: "41",value: "41",},
        {label: "42",value: "42",},
        {label: "43",value: "43",},
        {label: "44",value: "44",},
        {label: "45",value: "45",},
        {label: "46",value: "46",},
        {label: "47",value: "47",},
        {label: "48",value: "48",},
        {label: "49",value: "49",},
        {label: "50",value: "50",},
        {label: "51",value: "51",},
        {label: "52",value: "52",},
        {label: "53",value: "53",},
        {label: "54",value: "54",},
        {label: "55",value: "55",},
        {label: "56",value: "56",},
        {label: "57",value: "57",},
        {label: "58",value: "58",},
        {label: "59",value: "59",},
    ];

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignruntimes"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setStartDate(new Date(res.records.startDate))
                setEndDate(new Date(res.records.endDate))

                let schedule = JSON.parse(res.records.schedule)

                setMonSlider([schedule.mon_l, schedule.mon_u])
                setTueSlider([schedule.tue_l, schedule.tue_u])
                setWedSlider([schedule.wed_l, schedule.wed_u])
                setThuSlider([schedule.thu_l, schedule.thu_u])
                setFriSlider([schedule.fri_l, schedule.fri_u])
                setSatSlider([schedule.sat_l, schedule.sat_u])
                setSunSlider([schedule.sun_l, schedule.sun_u])
    
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/banner-landingpage')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-runtime'));

        let schedule = {
            mon_l: document.getElementById(`slider-monday-slider-value-lower`).innerHTML,
            mon_u: document.getElementById(`slider-monday-slider-value-upper`).innerHTML,
            tue_l: document.getElementById(`slider-tuesday-slider-value-lower`).innerHTML,
            tue_u: document.getElementById(`slider-tuesday-slider-value-upper`).innerHTML,
            wed_l: document.getElementById(`slider-wednesday-slider-value-lower`).innerHTML,
            wed_u: document.getElementById(`slider-wednesday-slider-value-upper`).innerHTML,
            thu_l: document.getElementById(`slider-thursday-slider-value-lower`).innerHTML,
            thu_u: document.getElementById(`slider-thursday-slider-value-upper`).innerHTML,
            fri_l: document.getElementById(`slider-friday-slider-value-lower`).innerHTML,
            fri_u: document.getElementById(`slider-friday-slider-value-upper`).innerHTML,
            sat_l: document.getElementById(`slider-saturday-slider-value-lower`).innerHTML,
            sat_u: document.getElementById(`slider-saturday-slider-value-upper`).innerHTML,
            sun_l: document.getElementById(`slider-sunday-slider-value-lower`).innerHTML,
            sun_u: document.getElementById(`slider-sunday-slider-value-upper`).innerHTML,
        }
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            startDate: startDate,
            endDate: endDate,
            schedule: JSON.stringify(schedule),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_RUNTIME, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/countries');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    const setSliderValue = (value) => {
        console.log(value)
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>5. Runtime</h1>
                        <p>Select the days your campaigns needs to run. You can also select specific time frames during the day in which the campaign needs to be live.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={9}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-runtime">
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="me-2">Start Date <span>*</span></Form.Label>
                                                    <DatePicker
                                                        className='form-control'
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                    />
                                                </Form.Group>
                                                <span id="form-error-startDate" className='form-input-error'></span>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="me-2">End Date <span>*</span></Form.Label>
                                                    <DatePicker
                                                        className='form-control'
                                                        minDate={startDate}
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                    />
                                                </Form.Group>
                                                <span id="form-error-endDate" className='form-input-error'></span>
                                            </Col>
                                        </Row>
                                        <div className="schedule-time-trame">
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>00:00</th>
                                                        <th>02:00</th>
                                                        <th>04:00</th>
                                                        <th>06:00</th>
                                                        <th>08:00</th>
                                                        <th>10:00</th>
                                                        <th>12:00</th>
                                                        <th>14:00</th>
                                                        <th>16:00</th>
                                                        <th>18:00</th>
                                                        <th>20:00</th>
                                                        <th>22:00</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Monday</th>
                                                        
                                                        <td colSpan={12}><TimeSlider sliderId="monday-slider" sliderValue={monSlider}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Tuesday</th>
                                                        <td colSpan={12}><TimeSlider sliderId="tuesday-slider" sliderValue={tueSlider}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Wednesday</th>
                                                        <td colSpan={12}><TimeSlider sliderId="wednesday-slider" sliderValue={wedSlider}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Thursday</th>
                                                        <td colSpan={12}><TimeSlider sliderId="thursday-slider" sliderValue={thuSlider}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Friday</th>
                                                        <td colSpan={12}><TimeSlider sliderId="friday-slider" sliderValue={friSlider}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Saturday</th>
                                                        <td colSpan={12}><TimeSlider sliderId="saturday-slider" sliderValue={satSlider}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sunday</th>
                                                        <td colSpan={12}><TimeSlider sliderId="sunday-slider" sliderValue={sunSlider}/></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>You can update the time slot. If you want to update, drag the time slot</p>
                                            {/* <h4>Edit ad Schedule</h4>
                                            <p>Edit here your advertising schedule. Hours are in UTC. End time must be later than start time.</p>
                                            <div className="edit-schedule-form">
                                                <div className="select-days-block">
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="All">All Days</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option>
                                                    </Form.Select>
                                                </div>
                                                <div className="select-time-block">
                                                    <div className="time-from-block">
                                                        <span>From</span>
                                                        <Form.Select aria-label="Default select example">
                                                            {minutes.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <span>:</span>
                                                        <Form.Select aria-label="Default select example">
                                                            {seconds.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                    <div className="time-to-block">
                                                        <span>To</span>
                                                        <Form.Select aria-label="Default select example">
                                                            {minutes.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <span>:</span>
                                                        <Form.Select aria-label="Default select example">
                                                            {seconds.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <span className="close-icon"><FontAwesomeIcon icon="fa-solid fa-xmark" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addmore-button">
                                                <Button variant="light" className="btn-medium"><FontAwesomeIcon icon="fa-solid fa-plus" /> add more</Button>
                                            </div> */}
                                            <span id="form-error-schedule" className='form-input-error'></span>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        {/* <div className="sec-foot-content">
                            <p>Depending the additional configuration and desired targeting of your campaign, the entered budget might not be entirely utilised. Normally speaking; the more precise targeting is applied, the less budget will be consumed. In no scenario there will be more budget utilised than the amount you have entered above.</p>
                        </div> */}
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Runtime Additional Info</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow2}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Runtime & Configured Budget</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Runtime Additional Info</div>
                <h5>Flexibility of Programmatic Display Advertising Campaigns</h5>
                <p>In today's fast-paced digital ecosystem, agility and precision are paramount. One of the standout features of our programmatic display advertising platform is its impressive runtime flexibility. Advertisers can rest assured that the lag time between campaign submission and activation is minimal. Specifically, campaigns can be initiated within a mere hour of submission.</p>
                <p>Moreover, the granularity with which campaigns can be managed is noteworthy. For instance, it is entirely feasible for a campaign to be halted at an exact time, say 16:43, only to be reactivated a short while later, at 17:11. This level of precision allows for an unparalleled ability to align advertising strategies with real-time market dynamics and specific audience behaviors.</p>
                <p>Our programmatic display advertising solution offers not only rapid campaign initiation but also meticulous control over runtime, ensuring that advertisers have both the speed and precision they require in the contemporary advertising landscape and gathering of intelligence.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Runtime & Configured Budget</div>
                <h5>Configured Runtime and Budget in Programmatic Display Campaigns</h5>
                <p>The efficiency of a programmatic display campaign depends heavily on two key parameters: the configured runtime and the set budget. These two parameters work hand in hand to determine the reach and overall impact of your campaign.</p>
                <p>There are instances where the runtime of the campaign may be too short or the campaign targeting too narrow for the allocated budget to be fully spent. For example, if you've set a sizable budget for a one-day campaign targeting only a tiny niche audience, there's a likelihood that not all of the funds will be utilized to reach that limited audience in the specified time frame. Should this happen, you, our valued client, will be reimbursed for the unspent portion of the budget. We recognize the importance of ensuring that your resources are used judiciously and that any unutilized funds are rightfully returned to you.</p>
                <p>Conversely, there might be instances where the campaign configuration and its targeting capture a much larger audience than anticipated. This audience might exceed what the maximum budget can cater for in terms of buying all available ad-spots. In such scenarios, it becomes our responsibility to ensure that your budget is used efficiently. We will evenly distribute the spend throughout the scheduled runtime, making sure your message consistently reaches your target audience over the entirety of the campaign period.</p>
                <p>In essence, while configuring the runtime and budget, it is pivotal to find a balance that ensures optimal reach without wastage. And in scenarios where discrepancies arise, rest assured that we are committed to ensuring the best outcome for your campaign and your budget.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Runtime;