import { Container, Row, Col, Table} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Aside from '../../components/Aside';
import { CAMPAIGN_LIST, fetchData } from '../../components/Service';
import CampaignData from '../../components/CampaignData';

const CampaignList = () => {
    const [listLoader, setListLoader] = useState(false)

    const [dataList, setDataList] = useState([])

    const getUsersList = (e) => {
        setListLoader(true);

        fetchData(CAMPAIGN_LIST, 'GET', "", true, false, (res) => {
            setListLoader(false);
            if (res.status === "success") {
                setDataList(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        getUsersList()
    }, [])

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <Row>
                    <Col lg={3} md={4}>
                        <Aside></Aside>
                    </Col>
                    <Col lg={9} md={8}>
                        <div className="dashboard-content-block">
                            <div className="dashbaord-heading">
                            Submitted Campaign
                            </div>
                            <div className="user-table-block">
                                <CampaignData viewType="admin"/>
                                {/* <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Campaign ID</th>
                                            <th>User's Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList && dataList.length ? dataList.map((campaign, i) => (
                                            <tr key={campaign.id}>
                                                <td>{campaign.id}</td>
                                                <td>{campaign.User && campaign.User.firstName} {campaign.User && campaign.User.lastName}</td>
                                                <td>{campaign.status}</td>
                                            </tr>
                                        )) : <tr><td colspan="5" className='text-center text-danger'>No Campaign Found</td></tr>}
                                    </tbody>
                                </Table> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>
        </>
    );
};

export default CampaignList;