import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_RICH_MEDIA, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';

const RichMedia = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [mediaType, setMediaType] = useState("WhatsApp");
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignrichmedia"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setMediaType(res.records.mediaType)
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/ip-targeting')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-rich-media'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            mediaType: mediaType,
            mediaPhone: formData.get("mediaPhone"),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_RICH_MEDIA, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/data-derived');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="heading-text-block">
                                    <h1>10. Rich Media</h1>
                                    <p>For information gathering purposes a banner can be based on a click-to-(messenger) protocol. After clicking on the banner, a person gets immediately forwarded to a messenger of choice. If the messenger is not installed on that particular phone, the user will be redirected to the AppStore or Google Play.</p>
                                </div>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-rich-media">
                                        <ul className="click-to-social-media-block">
                                            <li className={mediaType === "WhatsApp" ? "active" : ""} onClick={() => setMediaType("WhatsApp")}><img src= {'images/WhatsApp.svg'} alt="Connect X" /> WhatsApp</li>
                                            <li className={mediaType === "signal" ? "active" : ""} onClick={() => setMediaType("signal")}><img src= {'images/signal.svg'} alt="Connect X" /> Signal</li>
                                            <li className={mediaType === "telegram" ? "active" : ""} onClick={() => setMediaType("telegram")}><img src= {'images/telegram.svg'} alt="Connect X" /> Telegram</li>
                                        </ul>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Phone number where information can be forwarded to</Form.Label>
                                            <Form.Control type="text" name="mediaPhone" defaultValue={data && data.mediaPhone}/>
                                            <span id="form-error-mediaPhone" className='form-input-error' ></span>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                                <div className="sec-foot-content">
                                    <p>For optimal discretion, either for the information gathering unit, and the submitter of this information, Signal is to be advised.</p>
                                </div>
                                <div className="section-foot-block">
                                    <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Rich Media Creation</Button>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="left-side-block">
                                    <div className="aside-list-block">
                                        <img src= {'images/speed-of-deployment1.png'} className="countrypage-img" alt="Connect X" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Rich Media Creation</div>
                <h5>Rich Media Banners with CONNECTX Activation Platform</h5>
                <p>Welcome to the next-gen advertising experience! Our Rich Media banners are not your everyday banner ads. They combine state-of-the-art visuals with an interactive experience that not only grabs your audience's attention but also invites them to engage, paving the way to gather valuable information.</p>
                <h5>Why Choose Rich Media Banners?</h5>
                <ol>
                    <li><b>Advanced Visuals:</b> Stand out with banners that catch the eye and impress with their sophistication.</li>
                    <li><b>Interactive Engagement:</b> Encourage users to interact, fostering a deeper connection with your message.</li>
                    <li><b>Gather Information:</b> With our interactive scenarios, gather insights and data that help fine-tune your marketing efforts.</li>
                </ol>
                <h5>Direct Messaging Through Click-to-Messenger Protocols</h5>
                <p>A standout feature of our Rich Media banners is the click-to-messenger protocol. Users can directly click on the banner and be instantly forwarded to messaging platforms such as WhatsApp, Signal, or Telegram. This feature offers:</p>
                <ol>
                    <li><b>Choice of Messenger:</b> Select the platform you believe best suits your audience.</li>
                    <li><b>Immediate Data Forwarding:</b> Enter a phone number where the gathered information can be directly sent, streamlining your data collection process.</li>
                </ol>
                <h5>Getting Started: Let's Create Your Banner</h5>
                <p>Crafting your unique Rich Media banner is a breeze with CONNECTX. Here's how:</p>
                <ol>
                    <li><b>Materials:</b> Send us the design materials you'd like us to use.</li>
                    <li><b>Instructions:</b> Provide a brief description or instruction about your desired look, feel, and messaging for the banner.</li>
                    <li><b>Pricing:</b> If your campaign budget is $5,000 or more, we'll design this banner for you at no additional cost. For budgets below that, a design fee of $375 will apply.</li>
                </ol>
                <p>Step into the future of online advertising with Rich Media banners on the CONNECTX Activation platform. Create a visual statement and connect directly with your audience in a dynamic, interactive way. </p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default RichMedia;