import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_SUBMIT, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { PageLoader, displayError } from '../../components/Helper';

const SubmitCampaign = () => {

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [contactType, setContactType] = useState("")
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignsubmissions"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/reporting')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-submit'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            contactUsType: formData.get("contactUsType"),
            contactUs: formData.get("contactUs"),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_SUBMIT, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/thank-you');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>15. Submit Campaign</h1>
                        <p>By submitting your campaign configuration, a discrete backend operational unit will deploy the campaign on the shortest possible terms. Depending on your configuration you will receive updates and can see live results.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-submit">
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>How do you like us to contact you?</Form.Label>
                                            <Form.Select aria-label="select" name="contactUsType" defaultValue={data && data.contactUsType} onChange={(e) => setContactType(e.target.value)}>
                                                <option value="">Please choose an option</option>
                                                <option value="Secured E-Mail" selected={data && data.contactUsType === "Secured E-Mail"}>Secured E-Mail</option>
                                                <option value="Call" selected={data && data.contactUsType === "Call"}>Call</option>
                                                <option value="Chat" disabled>Chat</option>
                                                <option value="Signal" selected={data && data.contactUsType === "Signal"}>- Signal</option>
                                                <option value="Telegram" selected={data && data.contactUsType === "Telegram"}>- Telegram</option>
                                                <option value="Whatsapp" selected={data && data.contactUsType === "Whatsapp"}>- Whatsapp</option>
                                            </Form.Select>
                                            <span id="form-error-contactUsType" className='form-input-error'></span>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPhone">
                                            <Form.Label>{contactType === "Secured E-Mail" || (data && data.contactUsType === "Secured E-Mail") ? "Email" : "Phone number" }</Form.Label>
                                            <Form.Control type="text" name="contactUs" defaultValue={data && data.contactUs}/>
                                            <span id="form-error-contactUs" className='form-input-error' ></span>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="action-buttons-block">
                                    <Button role="button" className="btn btn-preview" onClick={(e) => previousData(e, "previous")} disabled={tinyloader}>
                                        {tinyloader && actionType ==="previous" && <Spinner animation="grow" size="sm" />} Previous
                                    </Button>
                                    <Button role="button" className="btn btn-primary" onClick={(e) => saveData(e, 'saveNext')} disabled={tinyloader}>
                                        {tinyloader && actionType ==="saveNext" && <Spinner animation="grow" size="sm" />} Submit Your Campaign
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>CONNECTX is a proprietary platform connected to vast amounts of inventory which can be utilised to deploy sensitive information in the form of banners, in virtually any country and any location. </p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Submitted your campaign, and now?</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Submitted your campaign, and now?</div>
                <h5>What Happens After You Submit Your Campaign via CONNECTX Activation Platform?</h5>
                <p>Congratulations on taking a step forward with your campaign! Here's what you can expect once you've submitted your campaign through the CONNECTX Activation platform:</p>
                <ol>
                    <li><b>Review of Additional Comments</b>: Our team will first review any doubts or questions you've shared in the "Additional Comments" section. We prioritize ensuring that all your queries are addressed, so you have clarity about the campaign process.</li>
                    <li><b>Communication Setup</b>: You'll hear from us soon! We will get in touch using a previously agreed-upon communication tool. If none was specified, we'll use one of the communication methods you've selected during the submission process.</li>
                    <li><b>Introducing Your Campaign to Publishers</b>: Depending on your campaign configuration, it will be pitched as a bid request to hundreds of thousands of publishers. What this essentially means is that your campaign is now in a digital marketplace, ready to find its audience.</li>
                    <li><b>Technical Breakdown</b>: Imagine an individual in a specific location of interest is browsing the internet. As they move across websites and apps, an ad request is generated in the background. This is where your campaign comes in! If the user's criteria match your campaign's specifications, they will be presented with the banner or message you've approved. It's real-time digital marketing magic!</li>
                    <li><b>Banner Creation by CONNECTX Activation</b>: If you've opted for CONNECTX Activation to design your banner, a separate line of communication will be set up prior to this step. Our design team will ensure the message aligns perfectly with your campaign objectives.</li>
                    <li><b>Letting Technology Take Over</b>: After the above steps, it's time for the technology to work its wonders. Our advanced algorithms and real-time bidding processes will ensure your campaign gets the exposure it deserves, targeting the right audience for your cause.</li>
                    <li><b>Continuous Development</b>: We're always evolving! Please note that certain sections and options on our platform are continuously being enhanced to offer you an even better experience.</li>
                </ol>
                <p>Your trust in CONNECTX Activation is highly valued, and we're committed to delivering results that exceed your expectations. Should you have any further questions or require assistance, our dedicated support team is always at your disposal.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default SubmitCampaign;