import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_BANNER, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, DownloadButton, PageLoader, displayError, uploadFileToAWSBucket } from '../../components/Helper';

const BannerLandingpage = () => {

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);
    const [Modal3, setModal3] = useState(false);
    const [Modal4, setModal4] = useState(false);
    const [Modal5, setModal5] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);
    const handleClose3 = () => setModal3(false);
    const handleShow3 = () => setModal3(true);
    const handleClose4 = () => setModal4(false);
    const handleShow4 = () => setModal4(true);
    const handleClose5 = () => setModal5(false);
    const handleShow5 = () => setModal5(true);

    const navigate = useNavigate()
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [imageBannerType, setImageBannerType] = useState("tag")
    const [videoBannerType, setVideoBannerType] = useState("tag")
    const [imageBanner, setImageBanner] = useState("")
    const [videoBanner, setVideoBanner] = useState("")
    const [nativeBannerIcon, setNativeBannerIcon] = useState("native-banner-icon.jpg")
    const [nativeBannerImage, setNativeBannerImage] = useState(null)
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignbanners"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setImageBanner(res.records.imageBanner)
                setVideoBanner(res.records.videoBanner)
                setImageBannerType(res.records.imageBannerType)
                setVideoBannerType(res.records.videoBannerType)
                setNativeBannerIcon(res.records.nativeBannerIcon)
                setNativeBannerImage(res.records.nativeBannerImage)
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = (e, type) => {
        navigate('/funding')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-banner'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            imageBannerType: imageBannerType,
            imageBanner: imageBanner,
            videoBannerType: videoBannerType,
            videoBanner: videoBanner,
            nativeBannerIcon: nativeBannerIcon,
            nativeBannerImage: nativeBannerImage,
            title: formData.get("title"),
            description: formData.get("description"),
            landingPageUrl: formData.get("landingPageUrl"),
            comments: formData.get("comments"),
        }

        setTinyloader(true)
        setActionType(type)

        fetchData(CAMPAIGN_BANNER, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/runtime');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    const uploadImageBanner = async (e, type) => {
        setImageBannerType(type)
        if(type === "image"){
            setFileUploadLoader("banner-image")
            let file = await uploadFileToAWSBucket(e)
            setFileUploadLoader(false)
        
            setImageBanner(file)
        } else {
            setImageBanner(e.target.value)
        }
    }

    const uploadVideoBanner = async (e, type) => {
        setVideoBannerType(type)
        if(type === "video"){
            setFileUploadLoader("banner-video")
            let file = await uploadFileToAWSBucket(e)
            setFileUploadLoader(false)
        
            setVideoBanner(file)
        } else {
            setVideoBanner(e.target.value)
        }
    }

    const uploadNativeBannerIcon = async (e) => {
        setFileUploadLoader("native-icon")
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setNativeBannerIcon(file)
    }

    const uploadNativeBannerImage = async (e) => {
        setFileUploadLoader("native-banner")
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setNativeBannerImage(file)
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>4. Banners & Landingpage</h1>
                        <p>Upload banners you want to be served on phones/devices and enter the landingpage for if a person clicks on the banner.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-banner">
                                        <div className="form-group custom-border-block">
                                            <label className="form-label">Image Banner</label>
                                            <div className="double-field-block">
                                                <div className="file-upload-block">
                                                    <Form.Control type="file" accept="image/*" onChange={(e) => uploadImageBanner(e, "image")}/>
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader === "banner-image" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload Images</Button>
                                                </div>
                                                <div className="orblock">or</div>
                                                <div className="cust-input-field-block">
                                                    <Form.Control type="text" placeholder="Enter image tag" onChange={(e) => uploadImageBanner(e, "tag")}  defaultValue={imageBanner ? (imageBannerType === "image" ? process.env.REACT_APP_AWS_S3_URL + imageBanner : imageBanner) : ""}/>
                                                </div>
                                            </div>
                                            <span id="form-error-imageBanner" className='form-input-error'></span>
                                            <div className="uploaded-image-show-block">
                                                <img src={imageBanner ? (imageBannerType === "image" ? process.env.REACT_APP_AWS_S3_URL + imageBanner : imageBanner) : `images/image-placeholder.jpg`} alt="" />
                                            </div>
                                        </div>
                                        <div className="form-group custom-border-block">
                                            <label className="form-label">Video Banner</label>
                                            <div className="double-field-block">
                                                <div className="file-upload-block">
                                                    <Form.Control type="file" accept="video/*" onChange={(e) => uploadVideoBanner(e, "video")}/>
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader === "banner-video" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload Video</Button>
                                                </div>
                                                <div className="orblock">or</div>
                                                <div className="cust-input-field-block">
                                                    <Form.Control type="text" placeholder="Enter video tag" onChange={(e) => uploadVideoBanner(e, "tag")}  defaultValue={videoBanner ? (videoBannerType === "video" ? process.env.REACT_APP_AWS_S3_URL + videoBanner : videoBanner) : ""}/>
                                                </div> 
                                            </div>
                                            {/* <div className="uploaded-image-show-block">
                                                <img src={'images/image-placeholder.jpg'} alt="" />
                                            </div> */}
                                            <span id="form-error-videoBanner" className='form-input-error'></span>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Native Banner</label>
                                            <div className="double-file-upload-block">
                                                <div className="file-upload-block me-4">
                                                    <Form.Control type="file" accept="image/*" onChange={(e) => uploadNativeBannerIcon(e)}/>
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader === "native-icon" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload  Icon</Button>
                                                    <span id="form-error-nativeBannerIcon" className='form-input-error'></span>
                                                </div>
                                                <div className="file-upload-block">
                                                    <Form.Control type="file" accept="image/*" onChange={(e) => uploadNativeBannerImage(e)}/>
                                                    <Button variant="info" className="file-upload-btn">{ fileUploadLoader === "native-banner" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload  Main Image</Button>
                                                    <span id="form-error-nativeBannerImage" className='form-input-error'></span>
                                                </div>
                                            </div>
                                            <div className="uploaded-image-show-block">
                                                <img src={nativeBannerIcon ? process.env.REACT_APP_AWS_S3_URL + nativeBannerIcon : `images/image-placeholder.jpg`} alt="" />
                                                <img src={nativeBannerImage ? process.env.REACT_APP_AWS_S3_URL + nativeBannerImage : `images/image-placeholder.jpg`} alt="" />
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Title (max 30 symbols)</Form.Label>
                                                    <Form.Control type="text" name="title" defaultValue={data && data.title}/>
                                                    <span id="form-error-title" className='form-input-error'></span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Description (max 45 symbols)</Form.Label>
                                                    <Form.Control type="text" name="description" defaultValue={data && data.description}/>
                                                    <span id="form-error-description" className='form-input-error'></span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Landing Page/URL</Form.Label>
                                                    <Form.Control type="text" name="landingPageUrl" defaultValue={data && data.landingPageUrl}/>
                                                    <span id="form-error-landingPageUrl" className='form-input-error'></span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className="special-content">Do you want us to create a (Rich Media; see section 8.) Banner for you? Email as much input; materials and desired message/text, to <a href="mailto:creation@connectx.solutions">creation@connectx.solutions</a>.</div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                            <span id="form-error-comments" className='form-input-error'></span>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                            <Col lg={5}>
                                <div className="left-side-block">
                                    <div className="aside-list-block">
                                        <h4>Recommended dimensions:</h4>
                                        <p>
                                            <b>Image Dimensions:</b> 320x50px, 300x250px, 320x480px <br />
                                            <b>Image Formats:</b> png, jpg <br />
                                            <b>Image Size (max):</b> 120kb <br />
                                            <b>Image tag:</b> Javascript, to be hosted on a 3rd party server
                                        </p>
                                        <Button variant="info" className="btn-medium" onClick={handleShow5}>What is a tag?</Button>
                                        <img src= {'images/banner-image.jpg'} alt="Connect X" />
                                    </div>
                                    <div className="aside-list-block">
                                        <p>
                                            <b>Video Dimensions:</b> 300x250, 320x480, 480x320 <br />
                                            mp4 video file or the tag itself (VAST, iFrame, VPaid) <br />
                                            <b>Max file size:</b> 2mb, duration 5-30 seconds
                                        </p>
                                        <Button variant="info" className="btn-medium" onClick={handleShow5}>What is a tag?</Button>
                                    </div>
                                    <div className="aside-list-block">
                                        <p>
                                            <b>Native:</b> <br />
                                            <b>Icon Size:</b> 192x192 <br />
                                            <b>Main Image Size:</b> 492x328 <br />
                                            <b>Title:</b> 30 symbols max <br />
                                            <b>Description:</b> 45 symbols max <br />
                                            <b>Formats:</b> jpg
                                        </p>
                                        <Button variant="info" className="btn-medium">What is a Native Banner?</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            {/* <p>Banners (either as an image, a video or a native ad) are usually served between news content. Imagine a person, in the country/region you want to target, starting his/her day with checking the (local) news, stops his alarm clock application or plays a game; exactly then your banner/message can appear on that phone/device.</p> */}
                            <p>Banners, whether in the form of an image, video, or native ad, typically appear amidst news content. Picture someone in your target country or region starting their day by checking local news, turning off their alarm, or playing a game. At that moment, your banner or message can be displayed on their device.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> What is a banner?</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow2}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Video Banner</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow3}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Rich Media Banner</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow4}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Click to Messenger Protocol</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">What is a banner?</div>
                <p>A banner is a form of digital advertising that is commonly displayed between the content of websites and apps. It serves as a visual advertisement that captures the attention of users as they navigate through online platforms. Banners are strategically placed to ensure visibility and engagement, often appearing at the top, bottom, or sides of a webpage or app interface.</p>
                <p>Banners have the remarkable capability to convey a wide range of information in a visually compelling manner. They can incorporate various elements such as images, text, animations, and interactive features to deliver messages effectively. The content of a banner can encompass promotional offers, product announcements, event notifications, brand awareness campaigns, and much more. This flexibility allows advertisers to tailor their messages to specific audiences and goals.</p>
                <p>Banners operate within the context of a globally accepted opt-in scenario. When users choose to visit a website or use an app, they implicitly accept the possibility of encountering commercial information within the content. Almost every website and app incorporates ad slots, which are designated areas where banners can be placed. This mechanism enables advertisers to effectively communicate their messages to diverse audiences around the world. The ubiquity of internet usage ensures that banners have the potential to reach a vast and varied demographic.</p>
                <p>In essence, banners serve as a dynamic conduit for disseminating information in the digital landscape. They seamlessly integrate into the user experience, providing a platform for businesses and organizations to engage with their target audience, drive brand visibility, and achieve marketing objectives in an interconnected world where the internet has become an integral part of daily life.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Video Banner</div>
                <p>A video banner is a dynamic and engaging form of digital advertising that utilizes video content to convey a message or promote a product, service, or cause. Unlike static banners, video banners capture viewers' attention through motion, sound, and visuals, offering a more immersive and impactful experience. These banners are typically embedded within webpages or apps and can be strategically placed to appear between content, such as news articles, to ensure maximum visibility.</p>
                <p>Video banners are often delivered in the popular MP4 format, allowing for smooth playback across a wide range of devices and platforms. However, advancements in technology have expanded the possibilities for delivering video banners, with various technical configurations becoming publishable. This adaptability ensures that the video content remains accessible and engaging, regardless of the viewer's device or connection speed.</p>
                <p>In today's programmatic advertising landscape, serving video messages has become highly efficient and cost-effective. Advanced targeting capabilities allow advertisers to reach specific audiences based on demographics, interests, behavior, and geographic location. This level of precision ensures that the video message reaches the right people at the right time, increasing the likelihood of a positive response.</p>
                <p>CONNECTX Activation specializes in creating high-end video messages designed to support a diverse array of causes. Leveraging their expertise, they craft compelling video content that resonates with viewers and drives meaningful engagement. By harnessing the power of video banners and programmatic advertising, CONNECTX Activation enables these messages to be delivered across an enormous network of webpages and apps. This strategic approach ensures that the impact of their campaigns is maximized, effectively spreading awareness and fostering action on a global scale.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal3} onHide={handleClose3} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Rich Media Banner</div>
                <h5>The Power of Rich Media Banners in the Age of Rapid Intelligence Gathering</h5>
                <p>In today's digitized world, the rapid transmission of information is not just a luxury, it's an expectation. Rich media banners, with their dynamic and engaging visuals, are at the forefront of this revolution, playing a pivotal role in changing the landscape of online advertising and intelligence gathering. At <b>CONNECTX Activation</b>, we understand this transformative power and channel it to benefit our clients and their noble causes.</p>
                <h5>Why Rich Media Banners?</h5>
                <p><b>1. Immediate Engagement:</b> Unlike traditional banners, rich media banners are interactive. They can include videos, animations, and other graphic elements that capture the attention and pique the curiosity of viewers.</p>
                <p><b>2. Enhanced Communication:</b> The visual elements embedded within rich media banners can amplify a message exponentially. As the adage goes, “A picture is worth a thousand words.” Imagine the impact of a short video or an animated graphic!</p>
                <p><b>3. Increased Click-through Rates:</b> The allure of rich media banners often translates to higher click-through rates, ensuring your message gets to more people.</p>
                <h5>The Magic of Click-to-Messenger Protocols</h5>
                <p>With the integration of click-to-messenger protocols, these banners take a leap into futuristic efficiency. They empower users to:</p>
                <ul>
                    <li>Instantly deliver intelligence on any topic with a single click.</li>
                    <li>Streamline the communication process, making the exchange of information faster and more efficient.</li>
                    <li>Engage in real-time discussions, ensuring timely feedback and more profound connections.</li>
                </ul>
                <h5>Visuals: Amplifying Impact</h5>
                <p>Visual communication is a universal language. It transcends barriers, making it one of the most powerful tools in the arsenal of modern-day marketing and information dissemination. The impact of a well-designed visual can linger, provoke thought, and stimulate action, making your message memorable.</p>
                <h5>Reaching the Masses with Programmatic Advertising</h5>
                <p>By leveraging the vast expanse of the programmatic advertising realm, we at <b>CONNECTX Activation</b> ensure that your message doesn't just reach millions but resonates with the right audience. Whether you're aiming for billions of devices worldwide or targeting a select few critical stakeholders, our rich media banners are crafted to:</p>
                <ul>
                    <li>Achieve unparalleled reach.</li>
                    <li>Encourage viewers to forward and share valuable information.</li>
                    <li>Enhance the spread of your message, ensuring it leaves an indelible mark.</li>
                </ul>
                <p><b>CONNECTX Activation</b> is committed to pioneering high-end rich media banners tailored to our clients' unique needs and causes. In an era where speed, efficiency, and impact are paramount, trust us to be your guiding star in the vast digital cosmos.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal4} onHide={handleClose4} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Click to Messenger Protocol</div>
                <h5>Advanced Banners for Quick Intelligence Gathering - CONNECTX Activation</h5>
                <p>In today's digital age, time is of the essence, especially when it comes to relaying critical information. Introducing Advanced Banners with Click-to-Messenger Capabilities. A revolutionary tool that can significantly reduce the time and effort it takes to share intelligence, potentially saving countless lives.</p>
                <h5>How It Works:</h5>
                <ul>
                    <li>One-Click Sharing: No more manual dialing or typing out lengthy reports. With a simple click, users can instantly share information on any given subject.</li>
                    <li>Instant Messenger Redirection: Once clicked, users are promptly forwarded to their messenger application of choice. This seamless redirection ensures that users are already familiar with the platform and can instantly share their intelligence.</li>
                    <li>Automatic Ingestion of Receiving Number: The system intuitively ingests the receiving phone number designated for the authorities. This ensures that the right personnel get the information without any additional steps from the user's end.</li>
                    <li>Quick Information Dissemination: Within mere seconds, users can share their intel, making sure that crucial moments aren't lost in transmission.</li>
                </ul>
                <h5>Why Choose CONNECTX Activation?</h5>
                <ul>
                    <li>Experience Matters: We have unparalleled experience in all click-to-messenger and click-to-call protocols, ensuring a smooth and effective operation.</li>
                    <li>Harnessing Programmatic Advertising: CONNECTX Activation integrates this functionality into the realm of programmatic advertising. This synergy not only amplifies the reach of these banners but also targets them more efficiently to the relevant audience.</li>
                    <li>Safety First: Our foremost priority is to create a secure channel for users to share their information. With stringent encryption protocols, we ensure that your data remains confidential and gets into the right hands.</li>
                </ul>
                <h5>The Impact</h5>
                <p>The potential of these advanced banners goes beyond mere convenience. By simplifying the process of intelligence sharing, we are not only promoting a culture of vigilance but are also equipping societies with tools to respond rapidly in emergency scenarios.</p>
                <p>In moments where every second counts, a swift transfer of information can be the difference between prevention and catastrophe.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal5} onHide={handleClose5} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">What is a tag?</div>
                <p>In the intricate world of digital advertising, the magic often lies in the details, one such detail being the Ad Tag.</p>
                <p>An ad tag is essentially the bridge between a publisher's website and the dynamic world of advertising content. It is a snippet of code, either in HTML or JavaScript, that integrates seamlessly into a publisher's site. But what does it really do?</p>
                <p>Think of an ad tag as a placeholder. It earmarks a specific space on a web page where an advertisement will appear. These tags aren't just simple placeholders though; they come equipped with detailed specifications, carrying crucial information such as:</p>
                <ul>
                    <li>The dimensions of the ad unit</li>
                    <li>The desired format (e.g., banner, video, or interstitial)</li>
                    <li>Other specific requirements or attributes of the ad</li>
                </ul>
                <p>This precision ensures that the right advertisement, of the right size and format, is displayed perfectly each time a visitor lands on the page.</p>
                <p>At CONNECTX Activation, we've honed our expertise in deploying ad tags. </p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default BannerLandingpage;