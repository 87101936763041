import { Container } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const ThankYou = () => {

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="thank-you-block">
                        <img src= {'images/thankyou.png'} alt="Connect X" />
                        <h1>Thank You</h1>
                        <h5>Your campaign has been submitted successfully.</h5>
                        <p>If you have any further queries, you can contact us at any time.</p>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>
        </>
    );
};

export default ThankYou;