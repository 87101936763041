const Footer = () => {
    return (
        <div className="footer-section">
            <img src={'/images/foot-logo.png'} alt="Connect X" />
            <p>Copyright © 2023 CONNECTX | All Rights Reserved.</p>
        </div>
    );
};

export default Footer;
