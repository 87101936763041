import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Table, Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Aside from '../../components/Aside';
import { USER_CREATE, USER_DELETE, USER_DETAIL, USER_LIST, USER_UPDATE, fetchData } from '../../components/Service';
import { displayError } from '../../components/Helper';

const Users = () => {
    const [listLoader, setListLoader] = useState(false)
    const [tinyLoader, setTinyLoader] = useState(false)

    const [userList, setUserList] = useState([])
    const [userData, setUserData] = useState()
    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);

    const getUsersList = (e) => {
        setListLoader(true);

        fetchData(USER_LIST, 'GET', "", true, false, (res) => {
            setListLoader(false);
            if (res.status === "success") {
                setUserList(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById(type + '-user'));
        
        let data = {
            id: userData && userData.id,
            firstName:  formData.get("firstName"),
            lastName:  formData.get("lastName"),
            userName:  formData.get("userName"),
            phone:  formData.get("phone"),
            email:  formData.get("email"),
            password:  formData.get("password"),
        }

        setTinyLoader(type)

        fetchData(type === "update" ? USER_UPDATE : USER_CREATE, 'POST', data, true, false, (res) => {
            setTinyLoader(false);

            if (res.status === "success") {
                handleClose1()
                handleClose2()
                getUsersList()
            } else {
                displayError(res.errors)
            }
        });
    }

    const getUser = (id) => {
        setTinyLoader("edit");

        fetchData(USER_DETAIL + "/" + id, 'GET', "", true, false, (res) => {
            setTinyLoader(false);
            if (res.status === "success") {
                setUserData(res.records)
                handleShow2()
            } else {
                // displayError(res.errors)
            }
        });
    }

    const deleteUser = (id) => {
        setTinyLoader("delete");

        fetchData(USER_DELETE + "/" + id, 'DELETE', "", true, false, (res) => {
            setTinyLoader(false);
            if (res.status === "success") {
                getUsersList()
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        getUsersList()
    }, [])

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <Row>
                    <Col lg={3} md={4}>
                        <Aside></Aside>
                    </Col>
                    <Col lg={9} md={8}>
                        <div className="dashboard-content-block">
                            <div className="dashbaord-heading">
                                Users
                            </div>
                            <div className="user-table-block">
                                <div className="create-user-block">
                                    <Button variant="primary" onClick={handleShow1}>Create User</Button>
                                </div>
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email Id</th>
                                            <th>Phone Number</th>
                                            <th>Username</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList && userList.length ? userList.map((user, i) => (
                                            <tr key={user.id}>
                                                <td>{user.id}</td>
                                                <td>{user.firstName} {user.lastName}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                <td>{user.userName}</td>
                                                <td>
                                                    <Button variant="edit" onClick={(e) => getUser(user.id)}><FontAwesomeIcon icon="fa-solid fa-pencil" /></Button>
                                                    <Button variant="delete" onClick={(e) => deleteUser(user.id)} disabled={tinyLoader === "delete"}>{ tinyLoader === "delete" ? <Spinner animation="grow" size="sm" /> : <FontAwesomeIcon icon="fa-solid fa-trash-can" /> }</Button>
                                                </td>
                                            </tr>
                                        )) : <tr><td colspan="5" className='text-center text-danger'>No User Found</td></tr>}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-dashboard-model" key="1">
            <Modal.Header closeButton>
            <Modal.Title>Create User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="create-user">
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>First Name *</Form.Label>
                                <Form.Control type="text" name="firstName"/>
                                <span id="form-error-firstName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="lastName"/>
                                <span id="form-error-lastName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email Id *</Form.Label>
                                <Form.Control type="email" name="email"/>
                                <span id="form-error-email" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number *</Form.Label>
                                <Form.Control type="text" name="phone"/>
                                <span id="form-error-phone" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username *</Form.Label>
                                <Form.Control type="text" name="userName"/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control type="text" name="password"/>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="dash-buttons-block">
                        <Button variant="save" onClick={(e) => saveData(e, 'create')} disabled={tinyLoader === "create"}>{ tinyLoader === "create" && <Spinner animation="grow" size="sm" /> } Add user</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-dashboard-model" key="2">
            <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="update-user">
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>First Name *</Form.Label>
                                <Form.Control type="text" name="firstName" defaultValue={userData && userData.firstName}/>
                                <span id="form-error-firstName" className='form-input-error'></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="lastName" defaultValue={userData && userData.lastName}/>
                                <span id="form-error-lastName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email Id *</Form.Label>
                                <Form.Control type="email" name="email" defaultValue={userData && userData.email}/>
                                <span id="form-error-email" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number *</Form.Label>
                                <Form.Control type="text" name="phone" defaultValue={userData && userData.phone}/>
                                <span id="form-error-phone" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username *</Form.Label>
                                <Form.Control type="text" name="userName" defaultValue={userData && userData.userName}/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control type="text" name="password"/>
                                <span className='small text-muted'>Leave it blank, if do not want to change.</span>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="dash-buttons-block">
                        <Button variant="save" onClick={(e) => saveData(e, 'update')} disabled={tinyLoader === "update"}>{ tinyLoader === "update" && <Spinner animation="grow" size="sm" /> } Edit user</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Users;