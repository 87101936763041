import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_BUDGET, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';

const CampaignBudget = () => {
    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignbudgets"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/campaign')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-budget'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            budget: formData.get("budget"),
            currency: formData.get("currency"),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_BUDGET, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/funding');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>2. Campaign budget</h1>
                        {/* <p>Enter the total budget that can be utilized for this campaign. It's not a certainty entire budget will be utilized.</p> */}
                        <p>Enter the total USD/EUR budget allocated for this campaign. Note: The full budget may not necessarily be used.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-budget">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Campaign Budget</Form.Label>
                                            <div className="withdropdown-input-blk">
                                                <Form.Select aria-label="Default select example" name="currency">
                                                    <option value="USD" selected={data && data.currency === "USD"}>USD ($)</option>
                                                    <option value="EUR" selected={data && data.currency === "EUR"}>EUR (€)</option>
                                                </Form.Select>
                                                <Form.Control type="text" name="budget" defaultValue={data && data.budget}/>
                                            </div>
                                            <span id="form-error-budget" className='form-input-error' ></span>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" name="comments" rows={3} defaultValue={data && data.comments}/>
                                            <span id="form-error-comments" className='form-input-error' ></span>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            <p>Depending the additional configuration and desired targeting of your campaign, the entered budget might not be entirely utilised. Normally speaking; the more precise targeting is applied, the less budget will be consumed. In no scenario there will be more budget utilised than the amount you have entered above.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Campaign Budget FAQ</Button>
                        <Button variant="info" className="btn-medium" onClick={handleShow2}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Anonymous Funding</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Campaign Budget FAQ - CONNECTX Activation</div>
                <h5>Why should I enter a maximum budget for my campaign?</h5>
                <p>Entering a maximum budget for your campaign on CONNECTX Activation is a crucial step that ensures your campaign stays within your desired spending limits. Our platform utilizes this budget as a safeguard to make sure that your campaign expenses never exceed the specified amount. This helps you maintain control over your advertising costs and prevents any unexpected overspending.</p>
                <h5>Is it possible that my entire budget won't be spent?</h5>
                <p>Yes, it's possible that not all of the budget you provide will be spent. The actual amount spent depends on various factors such as the targeting parameters, campaign configuration, and audience engagement. Campaigns on our platform are optimized for efficiency, meaning that we aim to reach your desired audience in the most cost-effective way. As a result, if the targeting is precise and the configuration is well-optimized, the spending might not utilize the full budget.</p>
                <h5>Why does the spending depend on the campaign setup and targeting?</h5>
                <p>The effectiveness of your campaign's targeting and configuration greatly impacts how your budget is utilized. If your campaign is finely tuned to reach a specific audience segment, the spending will be more concentrated on that segment, potentially leading to lower overall spending if the audience is limited. On the other hand, if the campaign aims to reach a broader audience, it might use up more of the budget.</p>
                <h5>How cost-effective is programmatic advertising on CONNECTX Activation?</h5>
                <p>Programmatic advertising on our platform is exceptionally cost-effective in terms of reach per dollar invested. Through programmatic advertising protocols, we're able to efficiently serve banners and messages to a large number of individual devices or phones for minimal cost. For instance, as little as 1 dollar can result in up to 1000 devices receiving your banner/message. This allows you to maximize the impact of your campaign while optimizing your budget allocation.</p>
                <h5>Additional Comments</h5>
                <p>At CONNECTX Activation, our goal is to empower you with the tools and insights needed to run successful campaigns without breaking the bank. By setting a maximum budget, you're taking a proactive step to control your campaign's financial aspects. Our platform's advanced targeting options and efficient programmatic advertising protocols ensure that your budget is used wisely to achieve your campaign objectives. Should you have any further questions or require assistance, our support team is always ready to help.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Anonymous Funding</div>
                <h5>Welcome to Anonymous Funding at CONNECTX Activation</h5>
                <p>At CONNECTX Activation, we understand the intricate landscape of campaigns, projects, and initiatives that require discreet and anonymous funding. With a wealth of experience working alongside diverse clients and specialized organizations, we recognize the importance of maintaining privacy and security while fueling your endeavors.</p>
                <h5>Our Expertise</h5>
                <p>Over the years, we have established ourselves as a trusted partner for those seeking alternative funding options that align with legal frameworks. Our team comprises experts who are well-versed in the nuances of anonymous funding, ensuring that your campaign's financial sources remain confidential. We've successfully assisted numerous clients from various sectors, enabling them to achieve their goals without compromising their values or integrity.</p>
                <h5>Navigating Boundaries with Integrity</h5>
                <p>At CONNECTX Activation, we take great pride in our commitment to upholding the law and maintaining ethical standards. Our approach to anonymous funding involves a thorough understanding of regulations and compliance measures to ensure that all financial activities remain within legal boundaries. This ensures that your campaign's reputation and objectives are protected at all times.</p>
                <h5>Discover Your Funding Alternatives</h5>
                <p>We invite you to explore the diverse range of funding alternatives we offer, each tailored to suit your unique requirements. Our team of dedicated professionals is ready to guide you through the process, discussing options that align with your campaign's objectives while ensuring utmost confidentiality.</p>
                <h5>Why Choose CONNECTX Activation?</h5>
                <ul>
                    <li>Proven Track Record: Our extensive experience with a wide array of clients and specialized organizations underscores our ability to navigate complex funding scenarios effectively.</li>
                    <li>Privacy Assurance: We prioritize your campaign's privacy and safeguard the identities of those involved in the funding process.</li>
                    <li>Ethical Practices: Our commitment to ethical conduct and compliance with legal regulations guarantees the integrity of your campaign.</li>
                    <li>Tailored Solutions: We understand that no two campaigns are alike. Our experts work closely with you to devise funding strategies that meet your specific goals.</li>
                </ul>
                <h5>Get in Touch</h5>
                <p>If you're ready to explore the world of anonymous funding and uncover funding solutions tailored to your campaign's needs, don't hesitate to reach out to us. Contact our team today for more information, and let's embark on a journey towards successful and discreet funding for your initiatives.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default CampaignBudget;