import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Select from 'react-select'
import { Context } from '../../components/Context';
import { CAMPAIGN_COUNTRIES, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import _ from 'lodash';

const Countries = () => {

    const [Modal1, setModal1] = useState(false);
    const [Modal2, setModal2] = useState(false);
    const [Modal3, setModal3] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);
    const handleClose3 = () => setModal3(false);
    const handleShow3 = () => setModal3(true);

    const options = [
        { value: "Afghanistan", label: "Afghanistan"},
        { value: "Albania", label: "Albania"},
        { value: "Algeria", label: "Algeria"},
        { value: "Andorra", label: "Andorra"},
        { value: "Angola", label: "Angola"},
        { value: "Antigua and Barbuda", label: "Antigua and Barbuda"},
        { value: "Argentina", label: "Argentina"},
        { value: "Armenia", label: "Armenia"},
        { value: "Austria", label: "Austria"},
        { value: "Azerbaijan", label: "Azerbaijan"},
        { value: "Bahrain", label: "Bahrain"},
        { value: "Bangladesh", label: "Bangladesh"},
        { value: "Barbados", label: "Barbados"},
        { value: "Belarus", label: "Belarus"},
        { value: "Belgium", label: "Belgium"},
        { value: "Belize", label: "Belize"},
        { value: "Benin", label: "Benin"},
        { value: "Bhutan", label: "Bhutan"},
        { value: "Bolivia", label: "Bolivia"},
        { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina"},
        { value: "Botswana", label: "Botswana"},
        { value: "Brazil", label: "Brazil"},
        { value: "Brunei", label: "Brunei"},
        { value: "Bulgaria", label: "Bulgaria"},
        { value: "Burkina Faso", label: "Burkina Faso"},
        { value: "Burundi", label: "Burundi"},
        { value: "Cabo Verde", label: "Cabo Verde"},
        { value: "Cambodia", label: "Cambodia"},
        { value: "Cameroon", label: "Cameroon"},
        { value: "Canada", label: "Canada"},
        { value: "Central African Republic", label: "Central African Republic"},
        { value: "Chad", label: "Chad"},
        { value: "Channel Islands", label: "Channel Islands"},
        { value: "Chile", label: "Chile"},
        { value: "China", label: "China"},
        { value: "Colombia", label: "Colombia"},
        { value: "Comoros", label: "Comoros"},
        { value: "Congo", label: "Congo"},
        { value: "Costa Rica", label: "Costa Rica"},
        { value: "Côte d'Ivoire", label: "Côte d'Ivoire"},
        { value: "Croatia", label: "Croatia"},
        { value: "Cuba", label: "Cuba"},
        { value: "Cyprus", label: "Cyprus"},
        { value: "Czech Republic", label: "Czech Republic"},
        { value: "Denmark", label: "Denmark"},
        { value: "Djibouti", label: "Djibouti"},
        { value: "Dominica", label: "Dominica"},
        { value: "Dominican Republic", label: "Dominican Republic"},
        { value: "DR Congo", label: "DR Congo"},
        { value: "Ecuador", label: "Ecuador"},
        { value: "Egypt", label: "Egypt"},
        { value: "El Salvador", label: "El Salvador"},
        { value: "Equatorial Guinea", label: "Equatorial Guinea"},
        { value: "Eritrea", label: "Eritrea"},
        { value: "Estonia", label: "Estonia"},
        { value: "Eswatini", label: "Eswatini"},
        { value: "Ethiopia", label: "Ethiopia"},
        { value: "Faeroe Islands", label: "Faeroe Islands"},
        { value: "Finland", label: "Finland"},
        { value: "France", label: "France"},
        { value: "French Guiana", label: "French Guiana"},
        { value: "Gabon", label: "Gabon"},
        { value: "Gambia", label: "Gambia"},
        { value: "Georgia", label: "Georgia"},
        { value: "Germany", label: "Germany"},
        { value: "Ghana", label: "Ghana"},
        { value: "Gibraltar", label: "Gibraltar"},
        { value: "Greece", label: "Greece"},
        { value: "Grenada", label: "Grenada"},
        { value: "Guatemala", label: "Guatemala"},
        { value: "Guinea", label: "Guinea"},
        { value: "Guinea-Bissau", label: "Guinea-Bissau"},
        { value: "Guyana", label: "Guyana"},
        { value: "Haiti", label: "Haiti"},
        { value: "Holy See", label: "Holy See"},
        { value: "Honduras", label: "Honduras"},
        { value: "Hong Kong", label: "Hong Kong"},
        { value: "Hungary", label: "Hungary"},
        { value: "Iceland", label: "Iceland"},
        { value: "India", label: "India"},
        { value: "Indonesia", label: "Indonesia"},
        { value: "Iran", label: "Iran"},
        { value: "Iraq", label: "Iraq"},
        { value: "Ireland", label: "Ireland"},
        { value: "Isle of Man", label: "Isle of Man"},
        { value: "Israel", label: "Israel"},
        { value: "Italy", label: "Italy"},
        { value: "Jamaica", label: "Jamaica"},
        { value: "Japan", label: "Japan"},
        { value: "Jordan", label: "Jordan"},
        { value: "Kazakhstan", label: "Kazakhstan"},
        { value: "Kenya", label: "Kenya"},
        { value: "Kuwait", label: "Kuwait"},
        { value: "Kyrgyzstan", label: "Kyrgyzstan"},
        { value: "Laos", label: "Laos"},
        { value: "Latvia", label: "Latvia"},
        { value: "Lebanon", label: "Lebanon"},
        { value: "Lesotho", label: "Lesotho"},
        { value: "Liberia", label: "Liberia"},
        { value: "Libya", label: "Libya"},
        { value: "Liechtenstein", label: "Liechtenstein"},
        { value: "Lithuania", label: "Lithuania"},
        { value: "Luxembourg", label: "Luxembourg"},
        { value: "Macao", label: "Macao"},
        { value: "Madagascar", label: "Madagascar"},
        { value: "Malawi", label: "Malawi"},
        { value: "Malaysia", label: "Malaysia"},
        { value: "Maldives", label: "Maldives"},
        { value: "Mali", label: "Mali"},
        { value: "Malta", label: "Malta"},
        { value: "Mauritania", label: "Mauritania"},
        { value: "Mauritius", label: "Mauritius"},
        { value: "Mayotte", label: "Mayotte"},
        { value: "Mexico", label: "Mexico"},
        { value: "Moldova", label: "Moldova"},
        { value: "Monaco", label: "Monaco"},
        { value: "Mongolia", label: "Mongolia"},
        { value: "Montenegro", label: "Montenegro"},
        { value: "Morocco", label: "Morocco"},
        { value: "Mozambique", label: "Mozambique"},
        { value: "Myanmar", label: "Myanmar"},
        { value: "Namibia", label: "Namibia"},
        { value: "Nepal", label: "Nepal"},
        { value: "Netherlands", label: "Netherlands"},
        { value: "Nicaragua", label: "Nicaragua"},
        { value: "Niger", label: "Niger"},
        { value: "Nigeria", label: "Nigeria"},
        { value: "North Korea", label: "North Korea"},
        { value: "North Macedonia", label: "North Macedonia"},
        { value: "Norway", label: "Norway"},
        { value: "Oman", label: "Oman"},
        { value: "Pakistan", label: "Pakistan"},
        { value: "Panama", label: "Panama"},
        { value: "Paraguay", label: "Paraguay"},
        { value: "Peru", label: "Peru"},
        { value: "Philippines", label: "Philippines"},
        { value: "Poland", label: "Poland"},
        { value: "Portugal", label: "Portugal"},
        { value: "Qatar", label: "Qatar"},
        { value: "Réunion", label: "Réunion"},
        { value: "Romania", label: "Romania"},
        { value: "Russia", label: "Russia"},
        { value: "Rwanda", label: "Rwanda"},
        { value: "Saint Helena", label: "Saint Helena"},
        { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis"},
        { value: "Saint Lucia", label: "Saint Lucia"},
        { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines"},
        { value: "San Marino", label: "San Marino"},
        { value: "Sao Tome & Principe", label: "Sao Tome & Principe"},
        { value: "Saudi Arabia", label: "Saudi Arabia"},
        { value: "Senegal", label: "Senegal"},
        { value: "Serbia", label: "Serbia"},
        { value: "Seychelles", label: "Seychelles"},
        { value: "Sierra Leone", label: "Sierra Leone"},
        { value: "Singapore", label: "Singapore"},
        { value: "Slovakia", label: "Slovakia"},
        { value: "Slovenia", label: "Slovenia"},
        { value: "Somalia", label: "Somalia"},
        { value: "South Africa", label: "South Africa"},
        { value: "South Korea", label: "South Korea"},
        { value: "South Sudan", label: "South Sudan"},
        { value: "Spain", label: "Spain"},
        { value: "Sri Lanka", label: "Sri Lanka"},
        { value: "State of Palestine", label: "State of Palestine"},
        { value: "Sudan", label: "Sudan"},
        { value: "Suriname", label: "Suriname"},
        { value: "Sweden", label: "Sweden"},
        { value: "Switzerland", label: "Switzerland"},
        { value: "Syria", label: "Syria"},
        { value: "Taiwan", label: "Taiwan"},
        { value: "Tajikistan", label: "Tajikistan"},
        { value: "Tanzania", label: "Tanzania"},
        { value: "Thailand", label: "Thailand"},
        { value: "The Bahamas", label: "The Bahamas"},
        { value: "Timor-Leste", label: "Timor-Leste"},
        { value: "Togo", label: "Togo"},
        { value: "Trinidad and Tobago", label: "Trinidad and Tobago"},
        { value: "Tunisia", label: "Tunisia"},
        { value: "Turkey", label: "Turkey"},
        { value: "Turkmenistan", label: "Turkmenistan"},
        { value: "Uganda", label: "Uganda"},
        { value: "Ukraine", label: "Ukraine"},
        { value: "United Arab Emirates", label: "United Arab Emirates"},
        { value: "United Kingdom", label: "United Kingdom"},
        { value: "United States", label: "United States"},
        { value: "Uruguay", label: "Uruguay"},
        { value: "Uzbekistan", label: "Uzbekistan"},
        { value: "Venezuela", label: "Venezuela"},
        { value: "Vietnam", label: "Vietnam"},
        { value: "Western Sahara", label: "Western Sahara"},
        { value: "Yemen", label: "Yemen"},
        { value: "Zambia", label: "Zambia"},
        { value: "Zimbabwe", label: "Zimbabwe"}
    ]

    const navigate = useNavigate();
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");
    const [countries, setCountries] = useState();
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaigncountries"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setCountries(res.records && res.records.conuntry ? JSON.parse(res.records.conuntry) : [])
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = () => {
        navigate('/runtime')
    }

    const saveData = (e, type) => {        
        let formData = new FormData(document.getElementById('campaign-country'));

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            conuntry: JSON.stringify(countries),
            comments: formData.get("comments"),
        }

        setTinyloader(true);
        setActionType(type)

        fetchData(CAMPAIGN_COUNTRIES, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/accuracy');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="heading-text-block">
                                    <h1>6. Countries</h1>
                                    <p>Select the country in which your campaign needs to run. It's possible to select multiple countries.</p>
                                </div>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-country">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Countries where campaign must run</Form.Label>
                                            { data ? 
                                                <Select key={1}
                                                    defaultValue={data && data.conuntry ? JSON.parse(data.conuntry) : []}
                                                    isMulti
                                                    name="countries[]"
                                                    options={options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(value) => setCountries(value)}
                                                /> : 
                                                <Select key={2}
                                                    isMulti
                                                    name="countries[]"
                                                    options={options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(value) => setCountries(value)}
                                                />
                                            }
                                            <span id="form-error-conuntry" className='form-input-error'></span>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="comments" defaultValue={data && data.comments}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                                
                                <div className="sec-foot-content">
                                    <p>It's possible to run your campaign in virtually any country. If people use the internet, visit sites and use apps, it's possible to get your message across. Even people in countries with heavily restricted internet access can potentially see your banner (e.g. by accessing the internet using hard-to-restrict technologies like Starlink).</p>
                                </div>
                                <div className="section-foot-block">
                                    <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Reach in every country - How?</Button>
                                    <Button variant="info" className="btn-medium" onClick={handleShow2}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Speed of deployment</Button>
                                    <Button variant="info" className="btn-medium" onClick={handleShow3}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Signal and Tor - safety</Button>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="left-side-block">
                                    <div className="aside-list-block">
                                        <img src= {'images/speed-of-deployment1.png'} className="countrypage-img" alt="Connect X" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Reach in every country - How?</div>
                <h5>Programmatic Display Advertising with CONNECTX Activation: How It Works Globally</h5>
                <p>The marvel of the modern digital era is its ability to connect, inform, and engage people no matter where they are in the world. The programmatic display advertising technology of CONNECTX Activation brings this capability to an impressive pinnacle.</p>
                <h5>Let's break it down:</h5>
                <p><b>1. Universal Access:</b> At its core, the internet is a global entity. Whether you're in a bustling city or a remote village, if you're online, you are a potential recipient of information. This means that anyone, anywhere, with internet access can potentially receive a message.</p>
                <p><b>2. Adslots:</b> Think of the vast array of websites and apps you visit daily - from reading the news to checking the weather. If any of these platforms have what's termed as an 'adslot', it's a gateway through which information can be pushed to you. This information typically takes the form of a banner ad.</p>
                <p><b>3. Precision Targeting:</b> One might wonder, with billions of users online, how can a message be directed to a specific audience? The brilliance of CONNECTX Activation technology lies in its ability to target very specifically. Imagine drawing a circle of just 100 meters in diameter on a map; only those within that circle can receive a certain piece of information if the campaign is set up that way. This technology is termed 'geo-fencing'.</p>
                <p><b>4. Reaching Remote Areas:</b> Until recently, many might have thought that remote or inaccessible areas would remain untouched by such advancements. But with the introduction of technologies like Starlink, even those in the most secluded parts of the world can access the internet.</p>
                <p><b>5. Overcoming Restrictions:</b> An added advantage of these newer internet technologies is their resistance to censorship. In countries or regions where governments may seek to control or limit information flow, it becomes increasingly difficult to restrict these advanced methods of communication.</p>
                <p>In essence, what CONNECTX Activation offers is a confluence of reach and precision, made possible by the seamless integration of programmatic display advertising technology and the ever-expanding global internet infrastructure. Whether you're in the heart of a metropolis or the depth of a desert, the digital world ensures you're always a click away from the information you need or want.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal2} onHide={handleClose2} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Speed of Deployment</div>
                <h5>Programmatic Advertising: A Swift Tool for Intelligence Gathering</h5>
                <p>In today's digital landscape, speed is of the essence. For businesses, government entities, or any organization aiming to gather intelligence or swiftly disseminate information, the ability to launch advertising campaigns rapidly is invaluable. Programmatic advertising emerges as a star player in this arena, boasting the ability to deploy campaigns within just an hour after receiving assets like banners, native ads, or videos. But what is it about programmatic advertising that enables such nimbleness, and why is it particularly beneficial for intelligence gathering? Here’s a deep dive into the subject:</p>
                <h5>1. Automated Buying and Placement:</h5>
                <p>The core idea behind programmatic advertising is automation. Traditional ad buying processes necessitate negotiations, manual placements, and prolonged set-ups. In contrast, programmatic platforms utilize algorithms and real-time data to instantly purchase ad space and position advertisements. This eliminates much of the manual legwork and time lags associated with traditional methods.</p>
                <h5>2. Real-time Bidding Systems:</h5>
                <p>One of the primary features of programmatic advertising is real-time bidding (RTB). Advertisers bid on an ad impression, and if they win, their ad is instantly displayed. This process happens in milliseconds and can thus deliver ads to audiences almost immediately after assets are received.</p>
                <h5>3. Precision and Personalization:</h5>
                <p>Programmatic platforms leverage vast amounts of data to target ads with great precision. For intelligence gathering, this means that specific demographics, locations, or interest groups can be targeted immediately, optimizing the speed at which feedback or data can be collected.</p>
                <h5>4. Dynamic Creative Optimization:</h5>
                <p>Many programmatic platforms support dynamic creative optimization. This feature allows for real-time modification of ad creatives based on user data. In critical situations, messages can be tweaked instantaneously, ensuring relevancy and timeliness.</p>
                <h5>5. Scale and Reach:</h5>
                <p>Programmatic platforms are integrated with a multitude of publishers and platforms. This means campaigns can achieve extensive reach quickly, spanning across various websites, apps, and digital spaces in no time.</p>
                <h5>6. Instant Feedback Loop:</h5>
                <p>With programmatic ads, advertisers receive immediate analytics on ad performance. This is invaluable for intelligence purposes, as the immediate feedback allows for real-time insights into audience behaviors, preferences, or sentiments.</p>
                <h5>7. Flexibility:</h5>
                <p>Should a situation change or new intelligence be acquired, programmatic campaigns can be paused, tweaked, or redirected in real-time. This level of adaptability is crucial in dynamic or critical situations.</p>
                <h5>Benefit for Intelligence Gathering in Critical Situations:</h5>
                <p>Imagine a scenario where there's an immediate need to gauge public sentiment on a pressing issue or to rapidly spread awareness about a critical event. Using programmatic advertising, one could instantly deploy a campaign targeting specific regions, demographics, or interest groups. As users interact with these ads, data can be collected in real-time, providing invaluable insights into the situation at hand.</p>
                <p>Furthermore, in situations where timely response is crucial — say, during natural disasters or public health crises — being able to disseminate information or gather intelligence within an hour could be the difference between chaos and coordinated response.</p>
                <p>The speed and efficiency of programmatic advertising, married to its precision and reach, make it a formidable tool in today's digital toolkit, especially when rapid intelligence gathering or information dissemination is the need of the hour.</p>
            </Modal.Body>
        </Modal>

        <Modal centered show={Modal3} onHide={handleClose3} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Signal and Tor - Safety</div>
                <h5>Using Secure Channels for Sharing Sensitive Information</h5>
                <p>In a world of heightened surveillance and increasing cyber threats, sharing sensitive information can be a perilous endeavor. This is particularly true in scenarios where individuals may possess knowledge on topics such as terrorist activities or cyber-attacks and wish to share this with the relevant authorities. In these contexts, safeguarding the identity and security of the informer is of paramount importance. This is where secure communication channels like the TOR browser and the Signal messenger app become invaluable.</p>
                <h5>1. Understanding the Risk:</h5>
                <p>For individuals who possess critical information on activities that are illicit, dangerous, or national security threats, there are very real risks involved in sharing this information. These can range from retaliation from those being exposed, legal ramifications, or unintentional exposure of the informant’s identity leading to personal danger.</p>
                <h5>2. Why TOR Browser:</h5>
                <p>The Onion Router (TOR) is a network that allows users to browse the internet anonymously. When you send information using TOR:</p>
                <ul>
                    <li><b>Anonymity:</b> Your data is routed through a series of volunteer-operated servers, making it extremely challenging for anyone (be it hackers, governments, or organizations) to determine who and where you are.</li>
                    <li><b>Encrypted Transmission:</b> Even if an adversary manages to access some parts of this transmission, the layered encryption ensures that they cannot easily understand the information being shared.</li>
                </ul>
                <p>For emails, TOR can be a game-changer. When using email services that prioritize privacy in conjunction with the TOR browser, informants can share sensitive data without easily revealing their identity.</p>
                <h5>3. Signal – The Gold Standard for Secure Messaging:</h5>
                <p>Signal is a messaging app that offers end-to-end encryption. This means:</p>
                <ul>
                    <li><b>Complete Privacy:</b> Only the sender and the recipient can read the message. Not even Signal, the company, can decrypt or access your communications.</li>
                    <li><b>Disappearing Messages:</b> Signal allows users to set timers on messages, after which they will be automatically deleted from both the sender's and receiver's devices.</li>
                    <li><b>No Metadata Storage:</b> Unlike other apps, Signal does not store metadata. This means there's no record of who spoke to whom and when, providing an additional layer of security.</li>
                </ul>
                <p>When you’re dealing with instantaneous communication or when emails might not be the preferred mode, Signal stands out as the safest messenger app for sharing critical information.</p>
                <p>In the realm of sensitive campaigns where the stakes are incredibly high, the tools we use to communicate are just as important as the information itself. For those at the receiving end, it's essential to educate and promote the usage of secure channels like the TOR browser and Signal. Encouraging informants to use these platforms ensures that they can share vital data with minimal risk, protecting both the informant and the integrity of the information.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Countries;