import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from './Context';

const Header = () => {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)

    const logout = (e) => {
        e.preventDefault()
        
        localStorage.removeItem('__cxtoken')
        setContext("")

        navigate("/")
    }

    return(
        <>
        <div className="header-section pages-header-section">
            {/* <div className="video-page-background">
                <video id="background-video" autoPlay muted loop poster="images/pages-header-poster.jpg">
                    <source src="https://connectx-image.s3.amazonaws.com/pages-header-video.mp4" type="video/mp4"/>
                </video>
            </div> */}
            <Navbar expand="lg">
                <Container fluid>
                    <Navbar.Brand href="/get-started"><img src= {'/images/logo.png'} alt="Connect X" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <NavDropdown title={<span>Support <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="arrow-down-icon-cust" /></span>} id="basic-nav-dropdown" className="support-dropdown-menu">
                                <NavDropdown.Item href="https://signal.me/#p/+918126707732)" target="_blank">
                                    <div className="link-with-icon">
                                        <img src= {'/images/signal.svg'} alt="Connect X" /> Signal
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="https://telegram.com/@rajchauhan_1" target="_blank">
                                    <div className="link-with-icon">
                                        <img src= {'/images/telegram.svg'} alt="Connect X" /> Telegram
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="https://wa.me/+8126707732" target="_blank">
                                    <div className="link-with-icon">
                                        <img src= {'/images/WhatsApp.svg'} alt="Connect X" /> WhatsApp
                                    </div>
                                </NavDropdown.Item>
                                {/* <NavDropdown.Item href="#action" target="_blank">
                                    <div className="link-with-icon">
                                        <img src= {'/images/slack.svg'} alt="Connect X" /> Slack
                                    </div>
                                </NavDropdown.Item> */}
                                <NavDropdown.Item href="skype:rajchauhan.er?chat" target="_blank">
                                    <div className="link-with-icon">
                                        <img src= {'/images/skype.svg'} alt="Connect X" /> Skype
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="mailto:rajchauhan.er@gmail.com" target="_blank">
                                    <div className="link-with-icon">
                                        <img src= {'/images/email.svg'} alt="Connect X" /> Email
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title={<span><FontAwesomeIcon icon="fa-solid fa-circle-user" className="user-icon-cust" /> {context && context.firstName} {context && context.lastName} <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="arrow-down-icon-cust" /></span>} id="basic-nav-dropdown" className="support-dropdown-menu">
                                { context && context.role !== "admin" && <NavDropdown.Item onClick={() => {navigate("/get-started")}}>
                                    Saved Campaigns
                                </NavDropdown.Item>}
                                <NavDropdown.Item onClick={logout}>
                                    Logout
                                </NavDropdown.Item>
                                {/* <NavLink href='javascript:void(0)' className="dropdown-item">Saved Campaigns</NavLink>
                                <NavLink href='javascript:void(0)' className="dropdown-item" onClick={logout}>Logout</NavLink> */}
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
        <Container fluid>
            <div className="secure-network-block">
            <FontAwesomeIcon icon="fa-solid fa-lock" /> Secured Network
            </div>
        </Container>
        <div id="full-page-loader" className="loader-block d-none">
            <div className="loader"></div>
        </div>
        </>
    );
};

export default Header;
