import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Context } from '../../components/Context';
import { CAMPAIGN_FUNDING, GET_CAMPAIGN_DETAIL, fetchData } from '../../components/Service';
import { ActionButton, PageLoader, displayError } from '../../components/Helper';
import _ from "lodash"

const Funding = () => {
    const navigate = useNavigate()
    const [context] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [actionType, setActionType] = useState("");

    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const [data, setData] = useState();

    const getData = () => {
        PageLoader(true);

        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            formType: "campaignfundings"
        }

        fetchData(GET_CAMPAIGN_DETAIL, 'POST', data, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setData(res.records)
            }
        });
    }

    useEffect(() => {
        getData()
    }, [])

    const previousData = (e, type) => {
        navigate('/campaign-budget')
    }

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('campaign-funding'));
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: (context && context.campaign && context.campaign.id) ? context.campaign.id : 0,
            fundingType: _.toString(formData.getAll("fundingType[]")),
            comments: formData.get("comments"),
        }

        setTinyloader(true)
        setActionType(type)

        fetchData(CAMPAIGN_FUNDING, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                if(type === "saveNext"){
                    navigate('/banner-landingpage');
                }
            } else {
                displayError(res.errors)
            }
        });
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container>
                <div className="inner-wrapper">
                    <div className="heading-text-block">
                        <h1>3. Funding</h1>
                        {/* <p>CONNECTX facilitates discrete methods of funding.</p> */}
                        <p>CONNECTX allows you to choose your preferred method of funding for this campaign, whether it's through an agency, directly from the client to the vendor, using alternative payment methods, or other options.</p>
                    </div>
                    <div className="connectx-block">
                        <Row>
                            <Col lg={7}>
                                <div className="connectx-inner-block">
                                    <Form id="campaign-funding">
                                        <div className="form-group">
                                            <label className="rc-block">Media Agency as Intermediary
                                                <input type="checkbox" name="fundingType[]" value="Media Agency as Intermediary" defaultChecked={data && data.fundingType.includes("Media Agency as Intermediary")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Direct Payment to DSP/Vendor
                                                <input type="checkbox" name="fundingType[]" value="Direct Payment to DSP/Vendor" defaultChecked={data && data.fundingType.includes("Direct Payment to DSP/Vendor")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Anonymous Crypto Payment to DSP/Vendor
                                                <input type="checkbox" name="fundingType[]" value="Anonymous Crypto Payment to DSP/Vendor" defaultChecked={data && data.fundingType.includes("Anonymous Crypto Payment to DSP/Vendor")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <label className="rc-block">Other Funding Methods (Contact Support)
                                                <input type="checkbox" name="fundingType[]" value="Other Funding Methods (Contact Support)" defaultChecked={data && data.fundingType.includes("Other Funding Methods (Contact Support)")}/>
                                                <span className="checkmark checkbox-cm"></span>
                                            </label>
                                            <span id="form-error-fundingType" className='form-input-error' ></span>
                                        </div>
                                        <Form.Group className="form-group">
                                            <Form.Label>Additional Comments or Questions</Form.Label>
                                            <Form.Control as="textarea" name="comments" rows={3} defaultValue={data && data.comments}/>
                                            <span id="form-error-comments" className='form-input-error' ></span>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <ActionButton previousData={previousData} saveData={saveData} tinyloader={tinyloader} actionType={actionType}/>
                            </Col>
                        </Row>
                        <div className="sec-foot-content">
                            {/* <p>For security and discretion related reasons CONNECTX offer discrete payment options. In normal scenarios TST can position itself as an intermediary between client and DSP/Vender. However, anonymous crypto payments to a DSP/Vendor - without TST in the middle - is also an option. Reach out to support if you want to discuss other funding scenarios.</p> */}
                            <p>For security and privacy, CONNECTX offers discreet payment options. In standard setups, the Media Agency can serve as an intermediary between the client and the DSP/Vendor. However, making direct anonymous crypto payments to a DSP/Vendor without involving the Media Agency is also an option. Please contact support if you'd like to discuss alternative funding methods.</p>
                        </div>
                    </div>
                    <div className="section-foot-block">
                        <Button variant="info" className="btn-medium" onClick={handleShow1}><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Funding Additional Info</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>

        <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
            <Modal.Header closeButton>
                <div className="model-head-logo"><img src={'images/logo.png'} alt="Connect X"/></div>
            </Modal.Header>
            <Modal.Body>
                <div className="main-heding">Funding Additional Info</div>
                <p>At CONNECTX Activation, we understand that a successful advertising campaign requires more than just creativity and strategy – it requires the right resources and funding. That's why we offer a range of innovative funding solutions to empower your campaigns and ensure seamless execution.</p>
                <p>Anonymous Funding Made Easy: In a digital age where privacy is paramount, we recognize the need for discretion. Our platform enables anonymous funding through a secure wallet, allowing seamless transfers of cryptocurrency. This approach not only ensures the confidentiality of transactions but also provides a level of security that traditional methods may not offer.</p>
                <p>Direct Vendor Payments: Simplifying the payment process is crucial for campaign efficiency. With CONNECTX Activation, you have the option to pay vendors and Demand-Side Platforms (DSPs) directly through our platform. This streamlines the funding process, enhancing transparency and reducing potential delays.</p>
                <p>Tailored Funding Structures: We understand that each client's needs are unique. That's why we offer the flexibility to create custom funding structures that protect both you and your campaigns from unnecessary scrutiny. Our team at TST is dedicated to constructing funding arrangements that align with your preferences, ensuring your campaigns operate smoothly and with confidence.</p>
                <p>At CONNECTX Activation, we believe that funding should never be a barrier to delivering impactful programmatic display advertising campaigns. With our diverse funding options, including anonymous wallet transfers, direct vendor payments, and personalised funding structures, we empower you to focus on what truly matters – creating compelling campaigns that captivate your audience.</p>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default Funding;