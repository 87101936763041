import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

const Header = () => {
    return(
        <div className="header-section">
            <Navbar expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#home"><img src= {'images/logo.png'} alt="Connect X"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <NavDropdown title={<span>Support <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="arrow-down-icon-cust" /></span>} id="basic-nav-dropdown" className="support-dropdown-menu">
                                <NavDropdown.Item href="https://signal.org/en/">
                                    <div className="link-with-icon">
                                        <img src= {'images/signal.svg'} alt="Connect X"/> Signal
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action">
                                    <div className="link-with-icon">
                                        <img src= {'images/slack.svg'}  alt="Connect X"/> Slack
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action">
                                    <div className="link-with-icon">
                                        <img src= {'images/telegram.svg'}  alt="Connect X"/> Telegram
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action">
                                    <div className="link-with-icon">
                                        <img src= {'images/skype.svg'}  alt="Connect X"/> Skype
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action">
                                    <div className="link-with-icon">
                                        <img src= {'images/email.svg'}  alt="Connect X"/> Email
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#home" className='user-login-block'>
                                <FontAwesomeIcon icon="fa-solid fa-circle-user" /> Login
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;
